import { Form, FormField } from "src/Components/ui/form";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import FormFieldItem from "src/common/form/FormFieldItem";
import { Input } from "src/Components/ui/input";
import { Button } from "src/Components/ui/button";
import { DialogClose } from "src/Components/ui/dialog";
import { socialMediaTypes } from "src/enums/socialMediaType";
import { SelectBoxForm } from "src/Components/selectbox/SelectBoxForm";

function SocialMediaForm(props) {
  const { t } = useTranslation();

  const dialogCloseRef = useRef(null);

  const formSchema = z.object({
    value: z
      .string({
        required_error: `${t("gloabl.url")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .startsWith("https://", {
        message: `${t("gloabl.url")} ${t("errorMessage.startwith")} https://.`,
      }),
    // .min(2, {
    // }),
    name: z
      .string({
        required_error: `${t("gloabl.name")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.name")} ${t("errorMessage.fieldCharsLength")}.`,
      }),
    type: z.any({
      required_error: `${t("gloabl.type")} ${t("errorMessage.fieldRequired")}.`,
    }),
  });

  // 1. Define your form.
  const form = useForm({
    resolver: zodResolver(formSchema),

    defaultValues: {
      ...props.datasource,
    },
  });

  const onSubmit = (values) => {
    console.log(values);

    props.onSubmit(values);
    if (dialogCloseRef.current) {
      dialogCloseRef.current.click();
    }
  };
  const onErorr = (errors) => {
    toast.error(t("errorMessage.fillAll"));
  };
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, onErorr)}
        className="space-y-8"
      >
        <FormField
          control={form.control}
          name="type"
          render={({ field }) => (
            <FormFieldItem required label={t("gloabl.type")} form={form}>
              <SelectBoxForm
                data={socialMediaTypes}
                {...field}
                placeholder={t("gloabl.select") + " " + t("gloabl.type")}
                label={"label"}
                valueKey={"id"}
              />
            </FormFieldItem>
          )}
        />
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormFieldItem required label={t("gloabl.name")} form={form}>
              <Input {...field} />
            </FormFieldItem>
          )}
        />
        <FormField
          control={form.control}
          name="value"
          render={({ field }) => (
            <FormFieldItem required label={t("gloabl.url")} form={form}>
              <Input {...field} />
            </FormFieldItem>
          )}
        />

        <div className="flex items-center gap-3 ">
          <DialogClose asChild>
            <Button type="button" variant="outline">
              {t("gloabl.cancel")}
            </Button>
          </DialogClose>
          <DialogClose ref={dialogCloseRef} />
          <Button type="submit">{t("gloabl.save")}</Button>
        </div>
      </form>
    </Form>
  );
}

export default SocialMediaForm;
