import React from "react";
import { useTranslation } from "react-i18next";
import { indexStore } from "../../../store/indexStore";
import Iterator from "src/Components/utils/Iterator";
import { IoIosAdd } from "react-icons/io";
import { Button } from "src/Components/ui/button";
import AddCategoryModel from "./AddCategoryModel";
import {
  CategoryCard,
  CategoryCardLoading,
} from "src/Components/Cards/CategoryCard";
import Visibility from "src/Components/utils/Visibility";

function Categories(props) {
  const { categoriesLoading, categories } = indexStore();

  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-5">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold">{t("dashbord.categories")}</h2>

        <AddCategoryModel
          trigger={
            <Button className="">
              <IoIosAdd />
              <span className="ml-2 hidden md:block">
                {t("gloabl.add")} {t(`gloabl.category`)}
              </span>
            </Button>
          }
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
        <Visibility visible={categoriesLoading}>
          <Iterator
            data={[1, 2, 3]}
            render={(row) => <CategoryCardLoading key={row} />}
          />
        </Visibility>
        <Visibility visible={!categoriesLoading}>
          <Iterator
            data={categories}
            render={(row) => <CategoryCard {...row} />}
          />
        </Visibility>
      </div>
    </div>
  );
}

export default Categories;
