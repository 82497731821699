import React from "react";
import { indexStore } from "../../store/indexStore";
import { useNavigate } from "react-router-dom";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { CiEdit } from "react-icons/ci";
import { MdOutlineDelete } from "react-icons/md";
import QRCodeGenerator from "../QRCodeGenerator/QRCodeGenerator";
import { useTranslation } from "react-i18next";
import { RiDownload2Line } from "react-icons/ri";
import { downloadPDF } from "../utils/DownloadQR";
import { AlertDialogs } from "../Dialgos/Alter-Daylogs";
import EditBranchModel from "../pages/branchs/EditBranchModel";
import { Skeleton } from "../ui/skeleton";

export function BranchCards(props) {
  const navigate = useNavigate();
  const {
    nameLang,
    DeleteBranchesFunction,
    resetOptions,
    restDataItemsFunction,
    setBranch,
    restDataStaticsFunction,
  } = indexStore();
  const handelDeleteBranch = (id) => {
    DeleteBranchesFunction(id);
  };
  const editBranch = (element) => {
    setBranch(element);
  };
  const handelClickOnBranch = (id) => {
    resetOptions();
    restDataItemsFunction();
    restDataStaticsFunction();
    navigate(`/branches/${id}/`);
  };
  const { t } = useTranslation();

  return (
    <div
      className="flex p-5 shadow-md border rounded-sm justify-between "
      key={props.element.id}
    >
      <div
        className="flex  flex-col justify-between gap-2 cursor-pointer"
        onClick={() => handelClickOnBranch(props.element.id)}
      >
        <p className="text-2xl font-bold">{props.element[nameLang]}</p>
        <p className="text-xl font-semibold">
          {props.element[nameLang === "name_ar" ? "address_ar" : "address_en"]}
        </p>
        <div className="flex gap-2 items-center">
          <MdOutlinePhoneInTalk />{" "}
          <p dir="ltr" className="">
            {props.element.phone}
          </p>
        </div>
        <div className="flex gap-2 items-center">
          <IoPhonePortraitOutline />
          <p dir="ltr" className="">
            {props.element.mobile}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-2 ">
        <div>
          <QRCodeGenerator
            value={`https://twhmenu.com/${localStorage.getItem("restName")}/${
              props.element.id
            }`}
          />
        </div>
        <div className="flex items-end justify-between gap-3">
          <EditBranchModel
            trigger={
              <CiEdit
                className="text-xl cursor-pointer"
                onClick={() => editBranch(props.element)}
              />
            }
          />

          <RiDownload2Line
            className="text-xl cursor-pointer  "
            onClick={downloadPDF}
          />
          <AlertDialogs
            trigger={
              <MdOutlineDelete className="text-xl cursor-pointer text-red-600 " />
            }
            variant="icon"
            onClickOk={() => handelDeleteBranch(props.element.id)}
            description={<spn>{t("gloabl.deleteMessage")}</spn>}
            title={`${t("gloabl.delete")} ${t(`gloabl.branch`)}`}
          />
        </div>
      </div>
    </div>
  );
}

export const BranchCardLoading = () => (
  <div className="flex p-5 shadow-md rounded-sm justify-between ">
    <div className="flex  flex-col justify-between gap-2 cursor-pointer">
      <Skeleton className="w-2/3 h-6" />
      <Skeleton className="w-2/3 h-5" />
      <div className="flex gap-2 items-center">
        <Skeleton className="w-36 h-4" />
      </div>
      <div className="flex gap-2 items-center">
        <Skeleton className="w-36 h-4" />
      </div>
    </div>
    <div className="flex flex-col gap-3 ">
      <div>
        <Skeleton className="w-32 h-32" />
      </div>
      <div className="flex items-end justify-between gap-3">
        <Skeleton className="w-5 h-5" />
        <Skeleton className="w-5 h-5" />
        <Skeleton className="w-5 h-5" />
      </div>
    </div>
  </div>
);
