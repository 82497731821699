import React from "react";
import OptionsForm from "./form/optionsForm";

// import { useParams, useNavigate } from "react-router-dom";
import { indexStore } from "../../../store/indexStore";
import { MainDialog } from "src/Components/Dialgos/MainDialog";
import { useTranslation } from "react-i18next";

function EditOptionModel(props) {
  const { t } = useTranslation();
  // const { branchId, id } = useParams();
  // const navigate = useNavigate();
  const { updateOptionFunction, option, fetchOptionbyIdFunction } =
    indexStore();
  const handelUpdateOption = (values) => {
    updateOptionFunction({ ...values, id: option.id });
  };

  // useEffect(() => {
  //   fetchOptionbyIdFunction(id);
  // }, []);
  return (
    <MainDialog
      trigger={props.trigger}
      title={t("gloabl.edit") + " " + t("options.option")}
    >
      <OptionsForm
        onSubmit={handelUpdateOption}
        editMode={true}
        datasource={option}
      />
    </MainDialog>
  );
}

export default EditOptionModel;
