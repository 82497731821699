

import { create } from "zustand";
import { createAppSlice } from "./createAppSlice";
import { createMenusSlice } from "./createMenusSlice ";
import { createCategoriesSlice } from "./createCategoriesSlice";
import { createItemsSlice } from "./createItemsSlice";
import { createPropertiesSlice } from "./createPropertiesSlice";
import { createBranchSlice } from "./createBranchSlice";
import { createRestautantSlice } from "./createRestaurantSlice";
import { createOptionsSlice } from "./createOptionsSlice";
import { createUserSlice } from "./createUserSlice";
import { createStatisticsSlice } from "./createStatisticsSlice";

export const indexStore = create((...a) => ({
  ...createAppSlice(...a),
  ...createMenusSlice(...a),
  ...createCategoriesSlice(...a),
  ...createItemsSlice(...a),
  ...createPropertiesSlice(...a),
  ...createBranchSlice(...a),
  ...createRestautantSlice(...a),
  ...createOptionsSlice(...a),
  ...createUserSlice(...a),
  ...createStatisticsSlice(...a),
}));
