import React from "react";
import BranchForm from "./form/branchForm";
import { indexStore } from "../../../store/indexStore";
import { useNavigate } from "react-router-dom";
import { MainDialog } from "src/Components/Dialgos/MainDialog";
import { useTranslation } from "react-i18next";

function AddBranchModel(props) {
  const { storeBranchesFunction } = indexStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handelAddBranch = (values) => {
    storeBranchesFunction({
      ...values,
      restaurant_id: localStorage.getItem("resId"),
      QR: "pppp",
    })
      .then((res) => {
        if (res.status === 200) navigate(`/branches`);
      })
      .catch((err) => console.log(err));
  };
  return (
    <MainDialog
      trigger={props.trigger}
      title={`${t("gloabl.add")} ${t(`gloabl.branch`)}`}
    >
      <BranchForm onSubmit={handelAddBranch} editMode={false} />
    </MainDialog>
  );
}

export default AddBranchModel;
