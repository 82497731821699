import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { IMG_URl } from "../../../../common/img_url";
import { indexStore } from "../../../../store/indexStore";

import { getUniqueCategories } from "../../../utils/UtilsFunctions";
import ImageSelected from "../../../Uploaders/ImageSelected";
import { Button } from "../../../ui/button";
import { DialogClose } from "src/Components/ui/dialog";
import { Form, FormField } from "src/Components/ui/form";
import FormFieldItem from "../../../../common/form/FormFieldItem";
import { Label } from "src/Components/ui/label";
import RestaurantTabs from "../../restaurant/RestaurantTabs";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Switch } from "src/Components/ui/switch";
import { Input } from "src/Components/ui/input";
import { ComboboxForm } from "src/Components/selectbox/ComboBoxForm";

function ItemForm(props) {
  const { nameLang, itemLoading, categories, menus } = indexStore();
  const { t } = useTranslation();
  const [image, setImage] = useState("");
  const [deleteImage, setDeleteImage] = useState(0);

  const dialogCloseRef = useRef(null);
  // make validation and form fields values conditionally
  const formSchema = z.object({
    name_ar: z
      .string({
        required_error: `${t("gloabl.name")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.name")} ${t("errorMessage.fieldCharsLength")}.`,
      }),
    name_en: z
      .string({
        required_error: `${t("gloabl.name")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.name")} ${t("errorMessage.fieldCharsLength")}.`,
      }),
    description_ar: z
      .string({
        required_error: `${t("gloabl.description")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.description")} ${t(
          "errorMessage.fieldCharsLength"
        )}.`,
      }),
    description_en: z
      .string({
        required_error: `${t("gloabl.description")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.description")} ${t(
          "errorMessage.fieldCharsLength"
        )}.`,
      }),
    category_id: z.number({
      required_error: `${t("gloabl.menu")} ${t("errorMessage.fieldRequired")}.`,
    }),
    price: z.union([
      z
        .string()
        .refine((val) => !isNaN(Number(val)), {
          message: `${t("gloabl.price")} ${t("errorMessage.fieldRequired")}.`,
        })
        .transform((val) => Number(val)),
      z.number({
        required_error: `${t("gloabl.price")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      }),
    ]),
    price_offer: z
      .union([
        z
          .string()
          .refine((val) => !isNaN(Number(val)))
          .transform((val) => Number(val)),
        z.number(),
      ])
      .optional(),
    new: z.any().optional(),
    common: z.any().optional(),
    unavailable: z.any().optional(),
    hidden: z.any().optional(),
    image: z
      .union([
        z.instanceof(File).refine((file) => file.size < 4 * 1024 * 1024, {
          message: "File size must be less than 4MB",
          required_error: `${t("gloabl.image")} ${t(
            "errorMessage.fieldRequired"
          )}.`,
        }),
        z.string({
          required_error: `${t("gloabl.image")} ${t(
            "errorMessage.fieldRequired"
          )}.`,
        }),
      ])
      .optional(),
  });

  // 1. Define your form.
  const form = useForm({
    resolver: zodResolver(formSchema),

    defaultValues: {
      ...props.datasource,
    },
  });

  const onErorr = (errors) => {
    toast.error(t("errorMessage.fillAll"));
  };

  const onSubmit = (values) => {
    let submitValues = {
      ...values,
      deleteImage: deleteImage,
      unavailable: values.unavailable ? 1 : 0,
      hidden: values.hidden ? 1 : 0,
      common: values.common ? 1 : 0,
      new: values.new ? 1 : 0,
    };
    // console.log(submitValues);

    props.onSubmit(submitValues);
    if (dialogCloseRef.current) {
      dialogCloseRef.current.click();
    }
  };
  useEffect(() => {
    if (props.editMode) {
      setImage(props.datasource.image ? IMG_URl + props.datasource.image : "");
    }
  }, [props.editMode]);

  const uniqueCategories = getUniqueCategories(categories, menus, nameLang);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, onErorr)}
        className="space-y-8"
      >
        <RestaurantTabs form={form} className="bg-transparent " />

        <FormField
          control={form.control}
          name="category_id"
          render={({ field }) => {
            return (
              <FormFieldItem required label={t("gloabl.category")} form={form}>
                <ComboboxForm
                  control={form.control}
                  name="category_id"
                  data={uniqueCategories}
                  {...field}
                  placeholder={t("gloabl.select") + " " + t("gloabl.category")}
                  lable={"displayName"}
                />
              </FormFieldItem>
            );
          }}
        />
        <ProductPrice form={form} t={t} />
        <ProductTags form={form} t={t} />
        <FormField
          control={form.control}
          name="image"
          render={({ field }) => {
            return (
              <FormFieldItem label={t("gloabl.image")} form={form}>
                <ImageSelected
                  form={form}
                  control={form.control}
                  name="image"
                  {...field}
                  editMode={props.editMode}
                  image={image}
                  setImage={setImage}
                  setDeleteImage={setDeleteImage}
                />
              </FormFieldItem>
            );
          }}
        />
        <div className="flex items-center gap-3 ">
          <DialogClose asChild>
            <Button type="button" variant="outline">
              {t("gloabl.cancel")}
            </Button>
          </DialogClose>
          <DialogClose ref={dialogCloseRef} />
          <Button type="submit">{t("gloabl.save")}</Button>
        </div>
      </form>
    </Form>
  );
}

export default ItemForm;

const ProductTags = ({ form, t }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
      <FormField
        control={form.control}
        name="common"
        render={({ field }) => (
          <FormFieldItem form={form}>
            <div className="flex items-center gap-2">
              <Switch
                dir="ltr"
                checked={field.value}
                onCheckedChange={field.onChange}
              />
              <Label>{t("gloabl.popularItem")}</Label>
            </div>
          </FormFieldItem>
        )}
      />
      <FormField
        control={form.control}
        name="new"
        render={({ field }) => (
          <FormFieldItem form={form}>
            <div className="flex items-center gap-2">
              <Switch
                dir="ltr"
                checked={field.value}
                onCheckedChange={field.onChange}
              />
              <Label>{t("gloabl.newItem")}</Label>
            </div>
          </FormFieldItem>
        )}
      />
      <FormField
        control={form.control}
        name="unavailable"
        render={({ field }) => (
          <FormFieldItem form={form}>
            <div className="flex items-center gap-2">
              <Switch
                dir="ltr"
                checked={field.value}
                onCheckedChange={field.onChange}
              />
              <Label>{t("gloabl.unavailable")}</Label>
            </div>
          </FormFieldItem>
        )}
      />{" "}
      <FormField
        control={form.control}
        name="hidden"
        render={({ field }) => (
          <FormFieldItem form={form}>
            <div className="flex items-center gap-2">
              <Switch
                dir="ltr"
                checked={field.value}
                onCheckedChange={field.onChange}
              />
              <Label>{t("gloabl.hidden")}</Label>
            </div>
          </FormFieldItem>
        )}
      />
    </div>
  );
};
const ProductPrice = ({ form, t }) => {
  return (
    <div>
      <FormField
        control={form.control}
        name="price"
        render={({ field }) => (
          <FormFieldItem required label={t("gloabl.price")} form={form}>
            <Input
              // {...form.register("price", {
              //   required: true,
              //   valueAsNumber: true,
              //   min: 0,
              // })}
              type="number"
              placeholder="0.00"
              {...field}
            />
          </FormFieldItem>
        )}
      />
      <FormField
        control={form.control}
        name="price_offer"
        render={({ field }) => (
          <FormFieldItem label={t("gloabl.price_offer")} form={form}>
            <Input placeholder="0.00" type="number" {...field} />
          </FormFieldItem>
        )}
      />
    </div>
  );
};

/*
        formatter={(value) =>
          ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
*/
