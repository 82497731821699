import React from "react";
import { IMG_URl } from "src/common/img_url";
import { AlertDialogs } from "../Dialgos/Alter-Daylogs";
import { MdOutlineDelete } from "react-icons/md";
import { indexStore } from "src/store/indexStore";
import { CiEdit } from "react-icons/ci";
import EditMenuModel from "../pages/menus/EditMenuModel";
import { useTranslation } from "react-i18next";
import { Skeleton } from "../ui/skeleton";

export function MenuCard(props) {
  const { t } = useTranslation();
  const { deleteMenuAction, setMenu, nameLang } = indexStore();
  const onDeleteMenu = (id) => {
    deleteMenuAction(id);
  };
  const editCategory = (element) => {
    setMenu(element);
  };
  return (
    <div
      key={props.id}
      className={`flex shadow-card-shadow rounded-md border p-3 gap-2  flex-col `}
    >
      <img
        src={`${IMG_URl}/${props.image}`}
        alt=""
        className={`object-cover rounded-md aspect-square w-full `}
      />

      <div className="text-center text-lg font-semibold">{props[nameLang]}</div>
      <div className="flex justify-between items-center">
        <EditMenuModel
          trigger={
            <CiEdit
              className="text-xl cursor-pointer"
              onClick={() => editCategory(props)}
            />
          }
        />

        <AlertDialogs
          trigger={
            <MdOutlineDelete className="text-xl cursor-pointer text-red-600 " />
          }
          variant="icon"
          onClickOk={() => onDeleteMenu(props.id)}
          description={<spn>{t("gloabl.deleteMessage")}</spn>}
          title={`${t("gloabl.delete")} ${t(`gloabl.menu`)}`}
        />
      </div>
    </div>
  );
}

export const MenuCardLoading = () => (
  <div
    className={`flex shadow-card-shadow rounded-md border p-3 gap-2  flex-col `}
  >
    <Skeleton className={`object-cover rounded-md aspect-square w-full `} />
    <Skeleton className={"w-16 h-4"} />
    <div className="flex justify-between items-center">
      <Skeleton className={"w-5 h-5"} />
      <Skeleton className={"w-5 h-5"} />
    </div>
  </div>
);
