import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { indexStore } from "../../../../store/indexStore";
import { Button } from "../../../ui/button";
import { DialogClose } from "@radix-ui/react-dialog";
import FormFieldItem from "../../../../common/form/FormFieldItem";
import { Form, FormField } from "src/Components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import BranchTabs from "../BranchTabs";
import { Input } from "src/Components/ui/input";
import ImageSelected from "src/Components/Uploaders/ImageSelected";
import { IMG_URl } from "src/common/img_url";

function BranchForm(props) {
  const { branchLoading } = indexStore();
  const { t } = useTranslation();

  const dialogCloseRef = useRef(null);
  // make validation and form fields values conditionally
  const formSchema = z.object({
    name_ar: z
      .string({
        required_error: `${t("gloabl.name")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.name")} ${t("errorMessage.fieldCharsLength")}.`,
      }),
    name_en: z
      .string({
        required_error: `${t("gloabl.name")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.name")} ${t("errorMessage.fieldCharsLength")}.`,
      }),
    address_ar: z
      .string({
        required_error: `${t("gloabl.name")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.name")} ${t("errorMessage.fieldCharsLength")}.`,
      }),
    address_en: z
      .string({
        required_error: `${t("gloabl.name")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.name")} ${t("errorMessage.fieldCharsLength")}.`,
      }),
    description_ar: z
      .string({
        required_error: `${t("gloabl.description")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.description")} ${t(
          "errorMessage.fieldCharsLength"
        )}.`,
      }),
    description_en: z
      .string({
        required_error: `${t("gloabl.description")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.description")} ${t(
          "errorMessage.fieldCharsLength"
        )}.`,
      }),
      mobile: z.string({
      required_error: `${t("gloabl.mobile")} ${t(
        "errorMessage.fieldRequired"
      )}.`
    }),
    phone: z.any().optional(),
    image_offer: z
      .union([
        z.instanceof(File).refine((file) => file.size < 4 * 1024 * 1024, {
          message: "File size must be less than 4MB",
          required_error: `${t("gloabl.logo")} ${t(
            "errorMessage.fieldRequired"
          )}.`,
        }),
        z.string({
          required_error: `${t("gloabl.logo")} ${t(
            "errorMessage.fieldRequired"
          )}.`,
        }),
      ])
      .optional(),

    image_common: z
      .union([
        z.instanceof(File).refine((file) => file.size < 4 * 1024 * 1024, {
          message: "File size must be less than 4MB",
          required_error: `${t("gloabl.logo")} ${t(
            "errorMessage.fieldRequired"
          )}.`,
        }),
        z.string({
          required_error: `${t("gloabl.logo")} ${t(
            "errorMessage.fieldRequired"
          )}.`,
        }),
      ])
      .optional(),
    image_new: z
      .union([
        z.instanceof(File).refine((file) => file.size < 4 * 1024 * 1024, {
          message: "File size must be less than 4MB",
          required_error: `${t("gloabl.logo")} ${t(
            "errorMessage.fieldRequired"
          )}.`,
        }),
        z.string({
          required_error: `${t("gloabl.logo")} ${t(
            "errorMessage.fieldRequired"
          )}.`,
        }),
      ])
      .optional(),
  });
  const [deleteCommonImage, setDeleteCommonImage] = useState(0);
  const [deleteOfferImage, setDeleteOfferImage] = useState(0);
  const [deleteNewImage, setDeleteNewImage] = useState(0);
  const [commonImage, setCommonImage] = useState("");
  const [offerImage, setOfferImage] = useState("");
  const [newImage, setNewImage] = useState("");
  // 1. Define your form.
  const form = useForm({
    resolver: zodResolver(formSchema),

    defaultValues: {
      ...props.datasource,
      image_common: commonImage,
      image_offer: offerImage,
      image_new: newImage,
    },
  });

  const onErorr = (errors) => {
    toast.error(t("errorMessage.fillAll"));
  };
  

  const onSubmit = (values) => {
    const submitValues = {
      ...values,
      delete_common: deleteCommonImage,
      delete_offer: deleteOfferImage,
      delete_new: deleteNewImage,
      // image_common: values.image_common ? values.image_common : "",
    };

    props.onSubmit(submitValues);
    if (dialogCloseRef.current) {
      dialogCloseRef.current.click();
    }
  };

  useEffect(() => {
    if (props.editMode) {
      setCommonImage(
        props.datasource.image_common
          ? IMG_URl + props.datasource.image_common
          : ""
      );
      setOfferImage(
        props.datasource.image_offer
          ? IMG_URl + props.datasource.image_offer
          : ""
      );
      setNewImage(
        props.datasource.image_new ? IMG_URl + props.datasource.image_new : ""
      );
    }
  }, [props.editMode]);
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, onErorr)}
        className="space-y-8"
      >
        <BranchTabs form={form} className="bg-transparent " />
        <FormField
          control={form.control}
          name="mobile"
          required
          render={({ field }) => (
            <FormFieldItem required label={t("gloabl.mobile")} form={form}>
              <div className="flex items-center gap-2">
                <Input
                  type="tel"
                  dir="ltr"
                  {...field}
                  // {...form.register("mobile", { required: true })}
                />
              </div>
            </FormFieldItem>
          )}
        />
        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => {
            return (
              <FormFieldItem label={t("gloabl.phone")} form={form}>
                <Input
                type="tel"
                dir="ltr" {...field} />
              </FormFieldItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="image_common"
          render={({ field }) => {
            return (
              <FormFieldItem
                description={t("messages.imageNotSelected")}
                label={`${t("gloabl.popularItem")} ${t("gloabl.anImage")}`}
                form={form}
              >
                <ImageSelected
                  form={form}
                  control={form.control}
                  name="image_common"
                  {...field}
                  editMode={props.editMode}
                  image={commonImage}
                  setImage={setCommonImage}
                  setDeleteImage={setDeleteCommonImage}
                />
              </FormFieldItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="image_offer"
          render={({ field }) => {
            return (
              <FormFieldItem
                description={t("messages.imageNotSelected")}
                label={`${t("gloabl.offers")} ${t("gloabl.anImage")}`}
                form={form}
              >
                <ImageSelected
                  form={form}
                  control={form.control}
                  name="image_offer"
                  {...field}
                  editMode={props.editMode}
                  image={offerImage}
                  setImage={setOfferImage}
                  setDeleteImage={setDeleteOfferImage}
                />
              </FormFieldItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="image_new"
          render={({ field }) => {
            return (
              <FormFieldItem
                description={t("messages.imageNotSelected")}
                label={`${t("gloabl.newItem")} ${t("gloabl.anImage")}`}
                form={form}
              >
                <ImageSelected
                  form={form}
                  control={form.control}
                  name="image_new"
                  {...field}
                  editMode={props.editMode}
                  image={newImage}
                  setImage={setNewImage}
                  setDeleteImage={setDeleteNewImage}
                />
              </FormFieldItem>
            );
          }}
        />

        <div className="flex items-center gap-3 ">
          <DialogClose asChild>
            <Button type="button" variant="outline">
              {t("gloabl.cancel")}
            </Button>
          </DialogClose>
          <Button type="submit">{t("gloabl.save")}</Button>
          <DialogClose ref={dialogCloseRef} />
        </div>
      </form>
    </Form>
  );
}

export default BranchForm;
