import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import MenuTabs from "../../menus/MenuTabs";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormField } from "src/Components/ui/form";
import FormFieldItem from "src/common/form/FormFieldItem";
import { Input } from "src/Components/ui/input";
import { toast } from "react-toastify";
import { DialogClose } from "src/Components/ui/dialog";
import { Button } from "src/Components/ui/button";

function SubOptionForm(props) {
  const { t } = useTranslation();

  const dialogCloseRef = useRef(null);

  const formSchema = z.object({
    name_ar: z
      .string({
        required_error: `${t("gloabl.name")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.name")} ${t("errorMessage.fieldCharsLength")}.`,
      }),
    name_en: z
      .string({
        required_error: `${t("gloabl.name")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.name")} ${t("errorMessage.fieldCharsLength")}.`,
      }),
    price: z.union([
      z
        .string()
        .refine((val) => !isNaN(Number(val)))
        .transform((val) => Number(val)),
      z.number(),
    ]),
  });

  // 1. Define your form.
  const form = useForm({
    resolver: zodResolver(formSchema),

    defaultValues: {
      ...props.datasource,
    },
  });

  const onSubmit = (values) => {
    if (!props.editMode) {
      delete values.id;
    }

    props.onSubmit(values);
    if (dialogCloseRef.current) {
      dialogCloseRef.current.click();
    }
  };
  const onErorr = (errors) => {
    toast.error(t("errorMessage.fillAll"));
  };
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, onErorr)}
        className="space-y-8"
      >
        <MenuTabs form={form} className="bg-transparent " />
        <FormField
          control={form.control}
          name="price"
          render={({ field }) => (
            <FormFieldItem label={t("gloabl.price")} form={form}>
              <Input type="number" placeholder="0.00" {...field} />
            </FormFieldItem>
          )}
        />
        <div className="flex items-center gap-3 ">
          <DialogClose asChild>
            <Button type="button" variant="outline">
              {t("gloabl.cancel")}
            </Button>
          </DialogClose>
          <DialogClose ref={dialogCloseRef} />
          <Button type="submit">{t("gloabl.save")}</Button>
        </div>
      </form>
    </Form>
  );
}

export default SubOptionForm;
