export const getUniqueCategories = (categories, menus, nameLang) => {
  return categories.map((category) => {
    const count = categories.filter(
      (cat) =>
        cat[nameLang]?.toLowerCase() === category[nameLang]?.toLowerCase()
    ).length;

    if (count > 1) {
      const menu = menus.find((menu) => menu.id === category.menu_id);
      const menuName = menu ? menu[nameLang] : category.menu_id;
      return {
        ...category,
        displayName: `${category[nameLang]} (${menuName})`,
      };
    }
    return {
      ...category,
      displayName: category[nameLang],
    };
  });
};
export const getUniqueItems = (items, categories, nameLang) => {
  return items.map((item) => {
    const count = items.filter(
      (it) =>
        it[nameLang]?.toLowerCase() === item[nameLang]?.toLowerCase()
    ).length;

    if (count > 1) {
      const menu = categories.find((menu) => menu.id === item.category_id);
      const menuName = menu ? menu[nameLang] : item.category_id;
      return {
        ...item,
        displayName: `${item[nameLang]} (${menuName})`,
      };
    }
    return {
      ...item,
      displayName: item[nameLang],
    };
  });
}
