import { axiosInstance } from "../axios-instance";
const menuRoot = "menu";
const catRoot = "category";
const catMenuServices = {
  getMenus: (branchId) => axiosInstance.get(`${menuRoot}/getMenu/${branchId}`),
  getMenu: (menuId) => axiosInstance.get(`${menuRoot}/show/${menuId}`),
  insertMenu: (payload) => axiosInstance.postForm(`${menuRoot}/store`, payload),
  updateMenu: (payload) =>
    axiosInstance.postForm(`${menuRoot}/update/${payload.id}`, payload),
  deleteMenu: (id) => axiosInstance.delete(`${menuRoot}/delete/${id}`),
  ////////////////////////////////////////////////////////////////////////
  getCats: (branchId) =>
    axiosInstance.get(`${catRoot}/getCategory/${branchId}`),
  getCat: (catId) => axiosInstance.get(`${catRoot}/show/${catId}`),
  insertCat: (payload) => axiosInstance.postForm(`${catRoot}/store`, payload),
  updateCat: (payload) =>
    axiosInstance.postForm(`${catRoot}/update/${payload.id}`, payload),
  deleteCat: (id) => axiosInstance.delete(`${catRoot}/delete/${id}`),
};
export default catMenuServices;
