import React from "react";
import { useTranslation } from "react-i18next";
import { indexStore } from "../../../store/indexStore";
import AddMenuModel from "./AddMenuModel";
import { Button } from "src/Components/ui/button";
import { IoIosAdd } from "react-icons/io";
import Iterator from "src/Components/utils/Iterator";
import { MenuCard, MenuCardLoading } from "src/Components/Cards/MenuCard";
import Visibility from "src/Components/utils/Visibility";

function Menus() {
  const { menus, menusLoading } = indexStore();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-5">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold">{t("dashbord.menus")}</h2>

        <AddMenuModel
          trigger={
            <Button className="">
              <IoIosAdd />
              <span className="ml-2 hidden md:block">
                {t("gloabl.add")} {t(`gloabl.menu`)}
              </span>
            </Button>
          }
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
        <Visibility visible={menusLoading}>
          {" "}
          <Iterator
            data={[1, 2, 3]}
            render={(row) => <MenuCardLoading key={row} />}
          />
        </Visibility>
        <Visibility visible={!menusLoading}>
          <Iterator data={menus} render={(row) => <MenuCard {...row} />} />
        </Visibility>
      </div>
    </div>
  );
}

export default Menus;
