import { showResponseMessage } from "../Components/utils/handellApiResponse";
import itemsServices from "../api-services/items-services";

export const createItemsSlice = (set) => ({
  items: [],
  sortItems: [],
  item: {},
  itemLoading: false,
  itemsLoading: false,
  categoryItemsOptionId: "",

  fetchItemsFunction: async (categoryId) => {
    set((state) => ({
      ...state,
      itemsLoading: true,
    }));
    try {
      const res = await itemsServices.getItemsByCatId(categoryId);
      set((state) => ({
        ...state,
        items: res.data.data,
        itemsLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        itemsLoading: false,
      }));
    }
  },
  fetchItemFunction: async (productId) => {
    set((state) => ({
      ...state,
      itemLoading: true,
    }));
    try {
      const res = await itemsServices.getItem(productId);
      set((state) => ({
        ...state,
        item: res.data.data,
        itemLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        itemLoading: false,
      }));
    }
  },
  insertItemsFunction: async (payload) => {
    set((state) => ({
      ...state,
      itemsLoading: true,
    }));
    try {
      const res = await showResponseMessage(
        itemsServices.insertItem(payload),
        "store"
      );

      set((state) => ({
        ...state,
        items: [...state.items, res.data.data],
        itemsLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        itemsLoading: false,
      }));
    }
  },
  updateItemsFunction: async (payload) => {
    set((state) => ({
      ...state,
      itemsLoading: true,
    }));
    try {
      const res = await showResponseMessage(
        itemsServices.updateItem(payload),
        "update"
      );

      set((state) => ({
        ...state,
        items: state.items.map((item) => {
          if (item.id == payload.id) item = res.data.data;
          return item;
        }),
        itemsLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        itemsLoading: false,
      }));
    }
  },
  deleteItemsFunction: async (id) => {
    set((state) => ({
      ...state,
      itemsLoading: true,
    }));
    try {
      const res = await showResponseMessage(
        itemsServices.deleteItem(id),
        "delete"
      );

      set((state) => ({
        ...state,
        items: state.items.filter((item) => item.id !== id),
        itemsLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        itemsLoading: false,
      }));
    }
  },
  getSortItemsFunction: async (id) => {
    set((state) => ({
      ...state,
      itemsLoading: true,
    }));
    try {
      const res = await itemsServices.getItemsByCatId(id);
      set((state) => ({
        ...state,
        sortItems: res.data.data,
        itemsLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        itemsLoading: false,
      }));
    }
  },
  restDataSortFunction: () => {
    set((state) => ({
      ...state,
      sortItems: [],
    }));
  },
  setCategoryItemsOptionId: (id) => {
    set({ categoryItemsOptionId: id });
  },
  ///////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////
  ////////////////// rest items /////////////////////////////
  ///////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////
  restDataItemsFunction: () => {
    set((state) => ({
      ...state,
      items: [],
      categoryItemsOptionId: "",
    }));
  },

  setItem: (item) => {
    set((state) => ({
      ...state,
      item: item,
    }));
  },
});
