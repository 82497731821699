import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { indexStore } from "../../../../store/indexStore";
import { getUniqueCategories } from "../../../utils/UtilsFunctions";
import { Button } from "../../../ui/button";
import { Form, FormField } from "src/Components/ui/form";
import { useForm } from "react-hook-form";
import FormFieldItem from "src/common/form/FormFieldItem";
import { ComboboxForm } from "src/Components/selectbox/ComboBoxForm";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { DialogClose } from "src/Components/ui/dialog";
import MenuTabs from "../../menus/MenuTabs";
import { Combobox } from "src/Components/selectbox/ComboBox";
import { toast } from "react-toastify";

function OptionsForm(props) {
  const { itemsOptions, categories, menus, nameLang } = indexStore();
  const { t } = useTranslation();

  const dialogCloseRef = useRef(null);

  const formSchema = z.object({
    name_ar: z
      .string({
        required_error: `${t("gloabl.name_ar")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.name_ar")} ${t(
          "errorMessage.fieldCharsLength"
        )}.`,
      }),
    name_en: z
      .string({
        required_error: `${t("gloabl.Password")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.Password")} ${t(
          "errorMessage.fieldCharsLength"
        )}.`,
      }),
    product_id: z.any({
      required_error: `${t("gloabl.itemName")} ${t(
        "errorMessage.fieldRequired"
      )}.`,
    }),
  });

  // 1. Define your form.
  const form = useForm({
    resolver: zodResolver(formSchema),

    defaultValues: {
      ...props.datasource,
    },
  });

  const [category, setCategory] = useState("");
  const [itemsSelected, setItemsSelected] = useState([]);

  const onSubmit = (values) => {
    const submitValues = {
      ...values,
      // hidden: values.hidden ? 1 : 0,
    };
    props.onSubmit(submitValues);
    if (dialogCloseRef.current) {
      dialogCloseRef.current.click();
    }
  };

  const onErorr = (errors) => {
    toast.error(t("errorMessage.fillAll"));
  };

  useEffect(() => {
    setItemsSelected(itemsOptions);
  }, [itemsOptions]);

  const onSelectCategory = (value, option) => {
    setCategory(value);
    const newItems = itemsOptions.filter((item) => {
      return item.category_id === value;
    });
    form.setValue("product_id", null);

    setItemsSelected(newItems);
  };

  const uniqueCategories = getUniqueCategories(categories, menus, nameLang);
  // const uniqueItems = getUniqueCategories(itemsSelected, categories, nameLang);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, onErorr)}
        className="space-y-8"
      >
        <MenuTabs form={form} className="bg-transparent " />
        <FormField
          control={form.control}
          name=""
          render={({ field }) => {
            return (
              <FormFieldItem label={t("gloabl.category")} form={form}>
                <Combobox
                  data={uniqueCategories}
                  {...field}
                  onChange={onSelectCategory}
                  value={category}
                  placeholder={t("gloabl.select") + " " + t("gloabl.category")}
                  lable={"displayName"}
                />
              </FormFieldItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="product_id"
          render={({ field }) => {
            return (
              <FormFieldItem required label={t("gloabl.item")} form={form}>
                <ComboboxForm
                  data={itemsSelected}
                  {...field}
                  placeholder={t("gloabl.select") + " " + t("gloabl.item")}
                  lable={nameLang}
                />
              </FormFieldItem>
            );
          }}
        />

        <div className="flex items-center gap-3 ">
          <DialogClose asChild>
            <Button type="button" variant="outline">
              {t("gloabl.cancel")}
            </Button>
          </DialogClose>
          <DialogClose ref={dialogCloseRef} />
          <Button type="submit">{t("gloabl.save")}</Button>
        </div>
      </form>
    </Form>
  );
}

export default OptionsForm;
