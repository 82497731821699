import { Route, Routes, useNavigate } from "react-router-dom";
import MainLayout from "./Components/layout/MainLayout";
import Login from "./login";
import { useEffect } from "react";
import BranchLayout from "./Components/layout/BranchLayout";
import "react-toastify/dist/ReactToastify.css";
import { Bounce, ToastContainer } from "react-toastify";

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    // Check if the token exists in local storage
    const token = localStorage.getItem("token");

    if (!token) {
      // Token doesn't exist, navigate to the login page
      navigate("/login");
    } else {
      // Token exists, stay in the app or perform additional checks
      // You can add more logic here if needed
    }
  }, [navigate]);

  return (
    <div className={``}>
      <Routes>
        <Route path="/*" element={<MainLayout />} />
        <Route path="/branches/:branchId/*" element={<BranchLayout />} />
        <Route path="/login" element={<Login />} />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </div>
  );
}

export default App;
