import axios from "axios";
import { toast } from "react-toastify";

const defaultOptions = {
  // baseURL: "https://restaurantapi.twhmenu.com/api",
  baseURL: "https://testapi.twhtest.com/api",

  headers: {
    Accept: "application/json",
  },
};

export const axiosInstance = axios.create(defaultOptions);

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = "Bearer " + token;
  }
  return config;
});
axiosInstance.interceptors.response.use(null, (error) => {
  if (error.response && error.response.status === 401) {
    toast.error(error.response.data.message);
    localStorage.removeItem("token");
    localStorage.removeItem("resId");
    localStorage.removeItem("premssions");
    window.location = "/login";
  }
  return Promise.reject(error);
});
