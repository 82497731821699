import React, { useEffect } from "react";
import MenusForm from "./form/menusForm";
import { dashbordType } from "../../../enums/dashbordType";

import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { indexStore } from "../../../store/indexStore";
import { MainDialog } from "src/Components/Dialgos/MainDialog";
import { useTranslation } from "react-i18next";

const EditMenuModel = (props) => {
  const { t } = useTranslation();
  const { updateMenuAction, menu } = indexStore();

  const handleSubmitAddCategory = (values) => {
    if (typeof values.image === "string") {
      delete values.image;
    }
    const payload = {
      ...values,
      id: menu.id,
    };
    updateMenuAction(payload);
  };

  return (
    <MainDialog
      // open={props.open}
      trigger={props.trigger}
      title={`${t("gloabl.edit")} ${t(`gloabl.menu`)}`}
    >
      <MenusForm
        type={dashbordType.menus}
        onSubmit={handleSubmitAddCategory}
        editMode={true}
        datasource={menu}
      />
    </MainDialog>
  );
};

export default EditMenuModel;
