import { showResponseMessage } from "../Components/utils/handellApiResponse";
import catMenuServices from "../api-services/cat-menu-services";
export const createCategoriesSlice = (set) => ({
  categories: [],
  category: {},
  categoryLoading: false,
  categoriesLoading: false,
  fetchCategoriesAction: async (branchId) => {
    set((state) => ({
      ...state,
      categoriesLoading: true,
    }));
    try {
      const res = await catMenuServices.getCats(branchId);
      set((state) => ({
        ...state,
        categories: res.data.data,
        categoriesLoading: false,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        categoriesLoading: false,
      }));
    }
  },
  fetchCategoryAction: async (categoryID) => {
    set((state) => ({
      ...state,
      categoryLoading: true,
    }));
    try {
      const res = await catMenuServices.getCat(categoryID);
      set((state) => ({
        ...state,
        category: res.data.data,
        categoryLoading: false,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        categoryLoading: false,
      }));
    }
  },

  insertCategoryAction: async (payload) => {
    set((state) => ({
      ...state,
      categoriesLoading: true,
    }));
    try {
      const res = await showResponseMessage(
        catMenuServices.insertCat(payload),
        "store"
      );
      set((state) => ({
        ...state,
        categories: [...state.categories, res.data.data],
        categoriesLoading: false,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        categoriesLoading: false,
      }));
    }
  },

  updateCategoryAction: async (payload) => {
    set((state) => ({
      ...state,
      categoriesLoading: true,
    }));
    try {
      const res = await showResponseMessage(
        catMenuServices.updateCat(payload),
        "update"
      );

      set((state) => ({
        ...state,

        categories: state.categories.map((e) => {
          if (e.id === payload.id) e = res.data.data;
          return e;
        }),
        categoriesLoading: false,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        categoriesLoading: false,
      }));
    }
  },
  deleteCategoryAction: async (payload) => {
    set((state) => ({
      ...state,
      categoriesLoading: true,
    }));
    try {
      const res = await showResponseMessage(
        catMenuServices.deleteCat(payload),
        "delete"
      );

      set((state) => ({
        ...state,
        categories: state.categories.filter((e) => e.id !== payload),
        categoriesLoading: false,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        categoriesLoading: false,
      }));
    }
  },
  setCategory: (data) => set((state) => ({ ...state, category: data })),
});
