import { Combobox } from "src/Components/selectbox/ComboBox";
import React, { useEffect } from "react";
import { indexStore } from "src/store/indexStore";
import { useTranslation } from "react-i18next";
import {
  formatDate,
  getFirstDayOfMonth,
  getToday,
} from "src/Components/utils/FormatDate";

import { DatePickerWithRange } from "src/Components/DatePicker/DatePickerRange";

import { useParams } from "react-router-dom";
import { Button } from "src/Components/ui/button";
import { ChartBart } from "src/Components/Charts/ChartBart";
import { toast } from "react-toastify";
import {  getUniqueItems } from "src/Components/utils/UtilsFunctions";

function ProductStatistics(props) {
  const {
    lang,
    fetchProductVistsAction,
    nameLang,
    ProductVistsArray,
    ProductSumNumVisit,
    ProductVistsLoading,
    productIdStatistics,
    products,
    fetchAllProducts,
    setProductIdStatistics,
    ProductName_ar,
    ProductName_en,
    ProductDate,
    categories
  } = indexStore();
  const { t } = useTranslation();
  const { branchId } = useParams();

  const [pDate, setPDate] = React.useState({
    from: getFirstDayOfMonth(),
    to: getToday(),
  });
  const handelSelectProduct = (value) => {
    setProductIdStatistics(value);
  };
  useEffect(() => {
    fetchAllProducts(branchId);
  }, []);

  const handelSubmitProduct = () => {
    if (productIdStatistics) {
      fetchProductVistsAction({
        name: "product",
        id: productIdStatistics,
        startDate: formatDate(pDate.from),
        endDate: formatDate(pDate.to),
      });
    } else {
      toast.error(t("gloabl.select") + " " + t("gloabl.item"));
    }
  };
  const uniqueItems = getUniqueItems(products, categories, nameLang);
console.log(categories);

  return (
    <div className="flex flex-col flex-wrap  justify-between gap-5 w-full">
      <h2 className="text-xl font-bold">
        {t("gloabl.item")} {t("gloabl.statistics")}
      </h2>
      <div className="w-full flex flex-wrap gap-2">
        <Combobox
          placeholder={t("gloabl.select") + " " + t("gloabl.item")}
          data={uniqueItems}
          value={productIdStatistics || null}
          onChange={(value, option) => handelSelectProduct(value, option)}
          lable={"displayName"}
        />
        <DatePickerWithRange date={pDate} setDate={setPDate} />
        <Button onClick={() => handelSubmitProduct()}>
          {t("gloabl.apply")}
        </Button>
      </div>
      <ChartBart
        layout="horizontal"
        date={ProductDate}
        classNameCard="w-full justify-between text-xl"
        data={ProductVistsArray}
        title={lang === "ar" ? ProductName_ar : ProductName_en}
        sum={ProductSumNumVisit}
        name={nameLang}
      />
    </div>
  );
}

export default ProductStatistics;
