import React from "react";
import { IMG_URl } from "src/common/img_url";
import Visibility from "../utils/Visibility";
import { indexStore } from "src/store/indexStore";
import { useTranslation } from "react-i18next";
import Spacer from "../utils/Spacer";
import { AlertDialogs } from "../Dialgos/Alter-Daylogs";
import { MdOutlineDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import EditItemsModel from "../pages/items/EditItemsModel";
import { Skeleton } from "../ui/skeleton";

export function ProductCard(props) {
  const { t } = useTranslation();
  const { nameLang, description, deleteItemsFunction, setItem } = indexStore();
  const onDeleteItem = (id) => {
    deleteItemsFunction(id);
  };
  const editItem = (element) => {
    setItem(element);
  };
  return (
    <div
      className={`flex shadow-card-shadow rounded-md p-3 gap-2 flex-col border `}
      key={props.id}
    >
      <img
        src={`${IMG_URl}${props.image}`}
        alt="img"
        className={`object-cover rounded-md aspect-square w-full`}
      />
      <ProductMed
        item={props}
        t={t}
        name={nameLang}
        description={description}
      />
      <Spacer flex />
      <ProductPrice item={props} name={nameLang} />
      <div className="flex justify-between items-center">
        <EditItemsModel
          trigger={
            <CiEdit
              className="text-xl cursor-pointer"
              onClick={() => editItem(props)}
            />
          }
        />

        <AlertDialogs
          trigger={
            <MdOutlineDelete className="text-xl cursor-pointer text-red-600 " />
          }
          variant="icon"
          onClickOk={() => onDeleteItem(props.id)}
          description={<spn>{t("gloabl.deleteMessage")}</spn>}
          title={`${t("gloabl.delete")} ${t(`gloabl.item`)}`}
        />
      </div>
    </div>
  );
}

const ProductMed = ({ item, t, name, description }) => {
  return (
    <div className="flex flex-col justify-between gap-2">
      <div className="flex flex-col gap-1">
        <p className="text-xs text-onBackground font-bold text-ellipsis line-clamp-1">
          {item[name]}
        </p>
        <p className="text-xs text-onBackground text-ellipsis line-clamp-2">
          {item[description]}
        </p>
      </div>
      <ProductTags item={item} t={t} />
    </div>
  );
};
const ProductTags = ({ item, t }) => {
  const tagClass = `text-xs px-2 py-1 text-white rounded-md `;
  return (
    <Visibility visible={item.new || item.unavailable || item.common}>
      <div className="flex gap-2 flex-wrap ">
        <Visibility visible={item.new == 1}>
          <p className={`${tagClass} bg-green-500`}>{t("gloabl.newItem")}</p>
        </Visibility>
        <Visibility visible={item.common == 1}>
          <p className={`${tagClass} bg-yellow-500`}>
            {t("gloabl.popularItem")}
          </p>
        </Visibility>
        <Visibility visible={item.unavailable == 1}>
          <p className={`${tagClass} bg-red-500`}>{t("gloabl.unavailable")}</p>
        </Visibility>
        <Visibility visible={item.hidden == 1}>
          <p className={`${tagClass} bg-black`}>{t("gloabl.hidden")}</p>
        </Visibility>
      </div>
    </Visibility>
  );
};

const ProductPrice = ({ item, name }) => {
  return (
    <div
      className={`flex shrink-0 justify-end gap-1 text-xs font-semibold text-onBackground  "flex-row justify-between" `}
    >
      <p
        className={` ${
          item.price_offer > 0
            ? "line-through decoration-red-500 decoration-solid decoration-2 "
            : ""
        }`}
      >
        {item.price} {name == "name_ar" ? "د" : "AED"}
      </p>
      <Visibility visible={item.price_offer > 0}>
        <p className="text-red-500 font-bold">
          {item.price_offer} {name == "name_ar" ? "د" : "AED"}
        </p>
      </Visibility>
    </div>
  );
};

export const ProductCardLoading = () => (
  <div
    className={`flex w-full shadow-card-shadow rounded-md p-3 gap-2 flex-col border `}
  >
    <Skeleton className={`object-cover rounded-md aspect-square w-full `} />
    <div className=" w-full flex flex-col justify-between gap-2">
      <div className="flex w-full flex-col gap-1">
        <Skeleton className={"w-1/3 h-5"} />
        <Skeleton className={"w-7/12 h-5"} />
      </div>
      <div className="flex gap-2 flex-wrap ">
        <Skeleton className={"w-5 h-5"} />
        <Skeleton className={"w-5 h-5"} />
        <Skeleton className={"w-5 h-5"} />
        <Skeleton className={"w-5 h-5"} />
      </div>
    </div>
    <Spacer flex />
    <div
      className={`flex shrink-0 justify-end gap-1 text-xs font-semibold text-onBackground  "flex-row justify-between" `}
    >
      <Skeleton className={"w-5 h-5"} />
      <Skeleton className={"w-5 h-5"} />
    </div>
    <div className="flex justify-between items-center">
      <Skeleton className={"w-5 h-5"} />
      <Skeleton className={"w-5 h-5"} />
    </div>
  </div>
);
