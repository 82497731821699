import { axiosInstance } from "../axios-instance";
const style = "style";
const socialMedia = "socialMedia";

const PropertiesServices = {
  getStyle: () => axiosInstance.get(`${style}/getStyle`),

  storeStyle: (payload) => axiosInstance.postForm(`${style}/store`, payload),
  updateStyle: (payload) =>
    axiosInstance.postForm(`${style}/update/${payload.id}`, payload),

  getSocailMedia: () => axiosInstance.get(`${socialMedia}/getSocialMedia`),
  updateSocailMedia: (payload) =>
    axiosInstance.post(`${socialMedia}/update/${payload.id}`, payload),
  storeSocailMedia: (payload) =>
    axiosInstance.post(`${socialMedia}/store`, payload),
  deleteSocailMedia: (id) =>
    axiosInstance.delete(`${socialMedia}/delete/${id}`),
};

export default PropertiesServices;
