import { axiosInstance } from "../axios-instance";

const restaurant = "restaurant";

const RestaurantsServices = {
  getRestaurants: () => axiosInstance.get(`${restaurant}`),
  getRestaurant: (restaurantId) =>
    axiosInstance.get(`${restaurant}/show/${restaurantId}`),
  storeRestaurant: (payload) =>
    axiosInstance.postForm(`${restaurant}/store`, payload),
  updateRestaurant: (payload) =>
    axiosInstance.postForm(`${restaurant}/update/${payload.id}`, payload),
  DeleteRestaurant: (id) => axiosInstance.delete(`${restaurant}/delete/${id}`),
  switchRestaurantByAdminByResId: (id) =>
    axiosInstance.get(`refreshWithIdRes/${id}`),
};

export default RestaurantsServices;
