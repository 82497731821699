import { ChartBart } from "src/Components/Charts/ChartBart";
import { DatePickerWithRange } from "src/Components/DatePicker/DatePickerRange";
import { Combobox } from "src/Components/selectbox/ComboBox";
import { Button } from "src/Components/ui/button";
import {
  formatDate,
  getFirstDayOfMonth,
  getToday,
} from "src/Components/utils/FormatDate";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { indexStore } from "src/store/indexStore";

function MenuStatistics() {
  const {
    lang,
    nameLang,
    MenuVistsArray,
    MenuSumNumVisit,
    MenuVistsLoading,
    MenuName_ar,
    MenuName_en,
    MenuIdStatistics,
    MenuDate,
    menus,
    fetchMenuVistsAction,
    setMenuIdStatistics,
  } = indexStore();
  const { t } = useTranslation();

  const [mDate, setMDate] = React.useState({
    from: getFirstDayOfMonth(),
    to: getToday(),
  });
  const handelSelectProduct = (value) => {
    setMenuIdStatistics(value);
  };

  const handelSubmitProduct = () => {
    if (MenuIdStatistics) {
      fetchMenuVistsAction({
        name: "menu",
        id: MenuIdStatistics,
        startDate: formatDate(mDate.from),
        endDate: formatDate(mDate.to),
      });
    } else {
      toast.error(t("gloabl.select") + " " + t("gloabl.menu"));
    }
  };

  return (
    <div className="flex flex-col flex-wrap  justify-between gap-5 w-full">
      <h2 className="text-xl font-bold">
        {t("gloabl.menu")} {t("gloabl.statistics")}
      </h2>
      <div className="w-full flex flex-wrap gap-2">
        <Combobox
          placeholder={t("gloabl.select") + " " + t("gloabl.menu")}
          data={menus}
          value={MenuIdStatistics || null}
          onChange={(value, option) => handelSelectProduct(value, option)}
          lable={nameLang}
        />
        <DatePickerWithRange date={mDate} setDate={setMDate} />
        <Button onClick={() => handelSubmitProduct()}>
          {t("gloabl.apply")}
        </Button>
      </div>
      <ChartBart
        layout="horizontal"
        date={MenuDate}
        classNameCard="w-full justify-between text-xl"
        data={MenuVistsArray}
        title={lang === "ar" ? MenuName_ar : MenuName_en}
        sum={MenuSumNumVisit}
        name={nameLang}
      />
    </div>
  );
}

export default MenuStatistics;
