import React from "react";
import BranchStatistics from "./BranchStatistics/BranchStatistics";
import ProductStatistics from "./ProductStatistics/ProductStatistics";
import MenuStatistics from "./MenuStatistics/MenuStatistics";
import CategoryStatistics from "./CategoryStatistics/CategoryStatistics";
import OSysteams from "./BranchStatistics/InfoStatistics/OSysteams";
import CitiesAndCountries from "./BranchStatistics/InfoStatistics/CitiesAndCountries/CitiesAndCountries";

function StatisticsIndex() {
  return (
    <div className="flex flex-col gap-5">
      <BranchStatistics />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <OSysteams />
        <MenuStatistics />
        <CategoryStatistics />
        <ProductStatistics />
      </div>
    </div>
  );
}

export default StatisticsIndex;
