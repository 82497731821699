import React from "react";
import ItemForm from "./form/itemForm";
import { dashbordType } from "../../../enums/dashbordType";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { indexStore } from "../../../store/indexStore";
import { MainDialog } from "src/Components/Dialgos/MainDialog";
import { useTranslation } from "react-i18next";

const AddItemsModel = (props) => {
  const { t } = useTranslation();
  const { categories, insertItemsFunction } = indexStore();

  const { branchId } = useParams();

  const navigate = useNavigate();

  const handleSubmitAddCategory = (values) => {
    insertItemsFunction({ ...values });
  };

  return (
    <MainDialog
      // open={props.open}
      trigger={props.trigger}
      title={`${t("gloabl.add")} ${t(`gloabl.item`)}`}
    >
      <ItemForm
        type={dashbordType.items}
        onSubmit={handleSubmitAddCategory}
        editMode={false}
        menusOptions={categories}
      />
    </MainDialog>
  );
};

export default AddItemsModel;
