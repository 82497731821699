import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ColorsPicker from "../../ColorsPicker/ColorsPicker";
import { indexStore } from "../../../store/indexStore";
import SocialMedia from "../../SocialMedia/SocialMedia";

import RestaurantsServices from "../../../api-services/restaurants-sevrices";
import Visibility from "../../utils/Visibility";
import { Combobox } from "src/Components/selectbox/ComboBox";
import { Skeleton } from "src/Components/ui/skeleton";

function ResturantHomePage(props) {
  const {
    fetchColorsFunction,
    fetchSocialMediaFunction,
    restaurants,
    fetchResturantsFunction,
    setRestaurantId,
    restaurantId,
    nameLang,
    restaurantsLoading,
  } = indexStore();
  const [restaurantObj, setRestaurantObj] = useState(null);

  const { t } = useTranslation();
  useEffect(() => {
    if (restaurants.length == 0) fetchResturantsFunction();
    fetchSocialMediaFunction();
    fetchColorsFunction();
    setRestaurantObj(Number(localStorage.getItem("resId")));
  }, [restaurantId, restaurants]);

  const handelSelectRestaurant = (value) => {
    localStorage.setItem("resId", value);
    const restaurant = restaurants.find((item) => item.id == value).domin;
    localStorage.setItem("restName", restaurant);
    RestaurantsServices.switchRestaurantByAdminByResId(value).then((res) => {
      localStorage.setItem("token", res.data.token);
      setRestaurantId(value);
    });
  };

  const per = Number(localStorage.getItem("premssions"));

  return (
    <div className="flex flex-col gap-7">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold">{t("dashbord.home")}</h2>
      </div>
      <Visibility visible={per === 0}>
        <div className="flex flex-col gap-3">
          <p>{t("gloabl.restaurantName")}</p>
          <Visibility visible={restaurantsLoading}>
            <Skeleton className={"w-72 h-10"} />
          </Visibility>
          <Visibility visible={!restaurantsLoading}>
            <Combobox
              placeholder={t("gloabl.restaurantName")}
              data={restaurants}
              value={restaurantObj || null}
              onChange={(value, option) =>
                handelSelectRestaurant(value, option)
              }
              lable={nameLang}
            />
          </Visibility>
        </div>

        <ColorsPicker />
      </Visibility>
      <SocialMedia />
    </div>
  );
}

export default ResturantHomePage;
