import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import DraggableTable from "../../dargDropTable/dragDropTable";

import itemsServices from "../../../api-services/items-services";
import { useLocation } from "react-router-dom";
import { indexStore } from "../../../store/indexStore";

import { getUniqueCategories } from "../../utils/UtilsFunctions";
import { Combobox } from "src/Components/selectbox/ComboBox";

function Sort(props) {
  const {
    sortItems,
    nameLang,
    categories,
    getSortItemsFunction,
    itemsLoading,
    restDataSortFunction,
    menus,
  } = indexStore();
  const { t } = useTranslation();
  const [category, setCategory] = useState();

  const location = useLocation();

  useEffect(() => {
    // const pathnameParts = location.pathname.split("/");
    // const lastParameter = pathnameParts[pathnameParts.length - 1];

    // if (lastParameter === "sort") {
    restDataSortFunction();
    // }
  }, [restDataSortFunction, location.pathname]);

  const onSelectCategory = (value, option) => {
    setCategory(value);
    getSortItemsFunction(value);
  };
  const onMove = (data) => {
    const d = { arr: data };
    itemsServices.sortItems(d);
  };

  const uniqueCategories = getUniqueCategories(categories, menus, nameLang);
  return (
    <div className="flex flex-col gap-5">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold">{t("dashbord.sort")}</h2>
      </div>
      <div className="flex gap-5">
        <Combobox
          data={uniqueCategories}
          onChange={onSelectCategory}
          value={category}
          placeholder={t("gloabl.select") + " " + t("gloabl.category")}
          lable={"displayName"}
        />
      </div>
      <DraggableTable
        dataSource={sortItems}
        onMove={onMove}
        loading={itemsLoading}
      />
    </div>
  );
}

export default Sort;
