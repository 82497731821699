import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "src/Components/ui/card";
import React from "react";
import { DataTable } from "src/Components/DataTable/DataTable";
import { oSysteamsColumns } from "./CitiesAndCountries/CountiesColmns";
import { indexStore } from "src/store/indexStore";
import { t } from "i18next";

function OSysteams(props) {
  const { oSysteam, infoTotal } = indexStore();
  const colOSyeteams = oSysteamsColumns(infoTotal);

  return (
    <Card>
      <CardHeader>
        <CardTitle>{` ${t("gloabl.oSystem")}`}</CardTitle>
        <CardDescription></CardDescription>
      </CardHeader>
      <CardContent>
        <DataTable columns={colOSyeteams} data={oSysteam} />
      </CardContent>
    </Card>
  );
}

export default OSysteams;
