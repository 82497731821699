import React from "react";
import { Sheet, SheetClose, SheetContent, SheetTrigger } from "../ui/sheet";
import { Button } from "../ui/button";
import { Menu } from "lucide-react";
import Iterator from "../utils/Iterator";
import { LinkItem } from "./LinkItem";
import { LogOutButton } from "./LinkItem";
import LangSelect from "../langSelect/langSelect";
import { indexStore } from "src/store/indexStore";
import { DarkModeToggle } from "../langSelect/DarkModeToggle";

export default function MobileNav(props) {
  const { lang } = indexStore();
  return (
    <div className="p-5 bg-backgroundConant md:hidden">
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon" className="shrink-0 md:hidden">
            <Menu className="h-5 w-5" />
          </Button>
        </SheetTrigger>
        <SheetContent
          side={lang === "ar" ? "right" : "left"}
          className="flex flex-col"
        >
          <nav className="flex flex-col gap-2 text-lg font-medium">
            <Iterator
              data={props.siderLinks}
              render={(e) => <LinkItem mobileNave item={e} />}
            />
          </nav>
          <LangSelect />
          <DarkModeToggle />
          <LogOutButton />
        </SheetContent>
      </Sheet>
    </div>
  );
}
