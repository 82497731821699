import React, { useEffect } from "react";
import { indexStore } from "../../../store/indexStore";
import Iterator from "../../utils/Iterator";
import { IoIosAdd } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { BranchCardLoading, BranchCards } from "../../Cards/BranchCards";
import Visibility from "../../utils/Visibility";
import { Button } from "../../ui/button";
import AddBranchModel from "./AddBranchModel";

function Branches() {
  const { t } = useTranslation();

  const { branches, featchBranchesFunction, branchsLoading } = indexStore();
  useEffect(() => {
    featchBranchesFunction();
  }, []);

  const per = Number(localStorage.getItem("premssions"));
  return (
    <div className="flex flex-col gap-5">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold">{t("gloabl.branches")}</h2>
        <Visibility visible={per === 0}>
          <AddBranchModel
            trigger={
              <Button>
                <IoIosAdd />
                <span className="ml-2 hidden md:block">
                  {t("gloabl.add")} {t(`gloabl.branch`)}
                </span>
              </Button>
            }
          />
        </Visibility>
      </div>
      <div className="grid grid-cols-1  md:grid-cols-2 gap-5">
        <Visibility visible={branchsLoading}>
          <Iterator
            data={[1, 23]}
            render={(e) => <BranchCardLoading key={e} />}
          />
        </Visibility>
        <Visibility visible={!branchsLoading}>
          <Iterator
            data={branches}
            render={(e) => <BranchCards element={e} key={e.id} />}
          />
        </Visibility>
      </div>
    </div>
  );
}

export default Branches;
