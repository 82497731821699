import React from "react";
import MenusForm from "./form/menusForm";
import { dashbordType } from "../../../enums/dashbordType";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { indexStore } from "../../../store/indexStore";
import { MainDialog } from "src/Components/Dialgos/MainDialog";
import { useTranslation } from "react-i18next";

const AddMenuModel = (props) => {
  const { t } = useTranslation();
  const { insertMenuAction } = indexStore();
  const { branchId } = useParams();

  const navigate = useNavigate();

  const handleSubmitAddCategory = (values) => {
    insertMenuAction({ ...values, branch_id: branchId }).then(() => {
      navigate(`/branches/${branchId}/menus`);
    });
  };

  return (
    <MainDialog
      // open={props.open}
      trigger={props.trigger}
      title={`${t("gloabl.add")} ${t(`gloabl.menu`)}`}
    >
      <MenusForm
        type={dashbordType.menus}
        onSubmit={handleSubmitAddCategory}
        editMode={false}
      />
    </MainDialog>
  );
};

export default AddMenuModel;
