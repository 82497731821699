import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { indexStore } from "../../store/indexStore";
import { SelectBox } from "../selectbox/Selectbox";

const LangSelect = () => {
  const { lang, changeLang } = indexStore();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
      document.documentElement.dir = i18n.dir();
    } else {
      changeLang(localStorage.getItem("Lang") || "en");
    }
    return () => {};
  }, [lang]);

  const langs = [
    {
      value: "ar",
      label: "عربية",
    },
    {
      value: "en",
      label: "English",
    },
  ];
  return (
    <div className="flex justify-start w-full">
      <SelectBox
        data={langs}
        value={lang}
        width="w-full"
        onValueChange={(value) => changeLang(value)}
        valueKey="value"
        label="label"
      />
    </div>
  );
};

export default LangSelect;
