import React, { useEffect } from "react";
import { indexStore } from "../../../store/indexStore";
import Iterator from "../../utils/Iterator";
import { IoIosAdd } from "react-icons/io";
import { useTranslation } from "react-i18next";
import RestaurantCard, {
  RestaurantCardLoaing,
} from "../../Cards/RestaurantCard";
import Visibility from "../../utils/Visibility";
import { Button } from "../../ui/button";
import AddResturantModel from "./AddRestaurantModel";

function Restaurants() {
  const { t } = useTranslation();
  const { restaurants, fetchResturantsFunction, restaurantsLoading } =
    indexStore();
  useEffect(() => {
    fetchResturantsFunction();
  }, []);

  return (
    <div className=" flex flex-col gap-5">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold">{t("gloabl.restaurants")}</h2>
        <AddResturantModel
          trigger={
            <Button className="">
              <IoIosAdd />
              <span className="ml-2 hidden md:block">
                {t("gloabl.add")} {t(`gloabl.restaurant`)}
              </span>
            </Button>
          }
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
        <Visibility visible={restaurantsLoading}>
          <Iterator
            data={[1, 2]}
            render={(e, i) => <RestaurantCardLoaing key={i} />}
          />
        </Visibility>
        <Visibility visible={!restaurantsLoading}>
          <Iterator
            data={restaurants}
            render={(e, i) => <RestaurantCard element={e} key={e.id} />}
          />
        </Visibility>
      </div>
    </div>
  );
}

export default Restaurants;
