import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { indexStore } from "../../../store/indexStore";
import { getUniqueCategories, getUniqueItems } from "../../utils/UtilsFunctions";
import Iterator from "src/Components/utils/Iterator";
import { Combobox } from "src/Components/selectbox/ComboBox";
import AddOptionModel from "./AddOptionModel";
import { Button } from "src/Components/ui/button";
import { IoIosAdd } from "react-icons/io";
import { OptionCard, OptionCardLoading } from "src/Components/Cards/OptionCard";
import Visibility from "src/Components/utils/Visibility";

function Options(props) {
  const { branchId } = useParams();
  const {
    nameLang,
    fetchOptionsOfItemsByIdFunction,
    fetchItemsOfOptionsFunction,
    itemIdOptions,
    setItemIdOptions,
    itemsOptions,
    ItemOptions,
    ItemOptionsLoading,
    categories,
    menus,
  } = indexStore();
  const { t } = useTranslation();
  const [itemsSelected, setItemsSelected] = useState(itemsOptions);
  const [category, setCategory] = useState();
  useEffect(() => {
    fetchItemsOfOptionsFunction(branchId);
    setItemsSelected(itemsOptions);
  }, [branchId]);

  const onSelect = (value, option) => {
    fetchOptionsOfItemsByIdFunction(value);
    setItemIdOptions(value);
  };

  const onSelectCategory = (value, option) => {
    setCategory(value);
    setItemIdOptions(null);
    const newItems = itemsOptions.filter((item) => {
      return item.category_id === value;
    });
    setItemsSelected(newItems);
  };
  const uniqueCategories = getUniqueCategories(categories, menus, nameLang);
  const uniqueItems = getUniqueItems(itemsSelected, categories, nameLang);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold">{t("dashbord.options")}</h2>

        <AddOptionModel
          trigger={
            <Button className="">
              <IoIosAdd />
              <span className="ml-2 hidden md:block">
                {t("gloabl.add")} {t(`gloabl.option`)}
              </span>
            </Button>
          }
        />
      </div>
      <div className="flex flex-col md:flex-row gap-5">
        <Combobox
          data={uniqueCategories}
          onChange={onSelectCategory}
          value={category}
          placeholder={t("gloabl.select") + " " + t("gloabl.category")}
          lable={"displayName"}
        />
        <Combobox
          data={uniqueItems}
          onChange={onSelect}
          value={itemIdOptions || null}
          placeholder={t("gloabl.select") + " " + t("gloabl.item")}
          lable={nameLang}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
        <Visibility visible={ItemOptionsLoading}>
          <Iterator
            data={[1, 2, 3]}
            render={(row) => <OptionCardLoading {...row} />}
          />
        </Visibility>
        <Visibility visible={!ItemOptionsLoading}>
          <Iterator
            data={ItemOptions}
            render={(row) => <OptionCard {...row} />}
          />
        </Visibility>
      </div>
    </div>
  );
}

export default Options;
