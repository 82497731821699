import React from "react";
import CategoryForm from "./form/categoryForm";
import { dashbordType } from "../../../enums/dashbordType";
import { indexStore } from "../../../store/indexStore";
import { MainDialog } from "src/Components/Dialgos/MainDialog";
import { useTranslation } from "react-i18next";

const AddCategoryModel = (props) => {
  const { t } = useTranslation();
  const { menus, insertCategoryAction } = indexStore();

  const handleSubmitAddCategory = (values) => {
    insertCategoryAction({ ...values });
  };

  return (
    <MainDialog
      // open={props.open}
      trigger={props.trigger}
      title={`${t("gloabl.add")} ${t(`gloabl.category`)}`}
    >
      <CategoryForm
        onSubmit={handleSubmitAddCategory}
        editMode={false}
        menusOptions={menus}
      />
    </MainDialog>
  );
};

export default AddCategoryModel;
