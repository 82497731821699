import { LuMenu } from "react-icons/lu";

import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { useEffect, useState } from "react";

import Iterator from "../utils/Iterator";
import { IMG_URl } from "src/common/img_url";
import { indexStore } from "src/store/indexStore";
import { Skeleton } from "../ui/skeleton";
import Visibility from "../utils/Visibility";

const Row = ({ name, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props.element.id,
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
        }
      : {}),
  };

  return (
    <div
      className="flex gap-5 items-center p-2 shadow-sm border rounded-sm "
      key={props.element.id}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <LuMenu
        ref={setActivatorNodeRef}
        style={{
          touchAction: "none",
          cursor: "move",
        }}
        {...listeners}
      />
      <img
        className="w-24 aspect-square object-cover"
        src={`${IMG_URl}/${props.element.image}`}
        alt=""
      />
      <p className="text-lg font-semibold">{props.element[name]}</p>
    </div>
  );
};

const App = (props) => {
  const [data, setData] = useState([]);
  const { nameLang } = indexStore();
  useEffect(() => {
    setData(props.dataSource);
  }, [props.dataSource]);

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      const newData = [];
      setData((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id);
        const overIndex = previous.findIndex((i) => i.id === over?.id);

        // Use the spread operator to create a new array with the updated order
        const updatedData = arrayMove(previous, activeIndex, overIndex);

        // Add the updated data to newData
        newData.push(...updatedData);

        // Return the updated array to set the state
        return updatedData;
      });
      let submitData = [];
      newData.forEach((item, index) => {
        submitData.push({
          id: item.id,
          sortNum: index + 1,
        });
      });

      props.onMove(submitData);
    }
  };

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        items={data.map((i) => i.id)}
        strategy={verticalListSortingStrategy}
      >
        <div className="flex flex-col gap-5">
          <Visibility visible={!props.loading}>
            <Iterator
              data={data}
              render={(e, i) => <Row element={e} key={i} name={nameLang} />}
            />
          </Visibility>
          <Visibility visible={props.loading}>
            <Iterator
              data={[1, 23]}
              render={(e, i) => <RowLoading key={i} />}
            />
          </Visibility>
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default App;

const RowLoading = () => (
  <div className="flex gap-5 items-center p-2 shadow-sm border rounded-sm ">
    <Skeleton className="w-7 h-7" />
    <Skeleton className="w-24 aspect-square object-cover" alt="" />

    <Skeleton className="w-32 h-4" />
  </div>
);
