import { BiCategoryAlt } from "react-icons/bi";
import { MdOutlineMenuBook } from "react-icons/md";
import { HiOutlineViewList } from "react-icons/hi";
import { FaSort } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { MdOutlineHome } from "react-icons/md";
import { FaArrowLeft } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa6";
import { indexStore } from "../../store/indexStore";
import { CgOptions } from "react-icons/cg";

export const BranchSliderLinks = () => {
  const { branchId } = useParams();
  const { lang } = indexStore();
  return [
    {
      icon: <MdOutlineHome className="h-4 w-4" />,
      title: "dashbord.home",
      link: `/branches/${branchId}/`,
    },
    {
      icon: <MdOutlineMenuBook className="h-4 w-4" />,
      title: "dashbord.menus",
      link: `/branches/${branchId}/menus`,
    },
    {
      icon: <BiCategoryAlt className="h-4 w-4" />,
      title: "dashbord.categories",
      link: `/branches/${branchId}/categories`,
    },
    {
      icon: <HiOutlineViewList className="h-4 w-4" />,
      title: "dashbord.items",
      link: `/branches/${branchId}/items`,
    },
    {
      icon: <CgOptions className="h-4 w-4" />,
      title: "dashbord.options",
      link: `/branches/${branchId}/options`,
    },
    {
      icon: <FaSort className="h-4 w-4" />,
      title: "dashbord.sort",
      link: `/branches/${branchId}/sort`,
    },
    {
      icon:
        lang == "en" ? (
          <FaArrowLeft className="h-4 w-4" />
        ) : (
          <FaArrowRight className="h-4 w-4" />
        ),
      title: "",
      link: `/branches/`,
    },
  ];
};
