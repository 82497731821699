import {
  BiLogoFacebook,
  BiLogoInstagramAlt,
  BiLogoWhatsapp,
  BiLogoTelegram,
  BiLogoSnapchat,
  BiLogoTwitter,
  BiMap,
} from "react-icons/bi";

export const socialMediaTypes = [
  { id: 0, label: <BiLogoFacebook className="social-media-icon" /> },
  { id: 1, label: <BiLogoInstagramAlt className="social-media-icon" /> },
  { id: 2, label: <BiLogoWhatsapp className="social-media-icon" /> },
  { id: 3, label: <BiLogoTelegram className="social-media-icon" /> },
  { id: 4, label: <BiLogoSnapchat className="social-media-icon" /> },
  { id: 5, label: <BiLogoTwitter className="social-media-icon" /> },
  { id: 6, label: <BiMap className="social-media-icon" /> },
];
