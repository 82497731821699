import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./i18n";
import "./style/output.css";
import { ThemeProvider } from "./Components/ui/theme-provider";
import { AblyProvider } from "ably/react";
import * as Ably from "ably";
const root = ReactDOM.createRoot(document.getElementById("root"));
const client = new Ably.Realtime({
  key: "6jY4lw.wSFrTw:_FCLjMx0u5670bHjTKtGwKEj42beEriZu1190F-OY38",
});
root.render(
  <Router>
    <ThemeProvider
      attribute="class"
      defaultTheme="light"
      enableSystem
      disableTransitionOnChange
    >
      <AblyProvider client={client}>
        <App />
      </AblyProvider>
    </ThemeProvider>
  </Router>
);
