import { MainDialog } from "src/Components/Dialgos/MainDialog";
import React from "react";
import { useTranslation } from "react-i18next";
import { indexStore } from "src/store/indexStore";
import UserForm from "./form/UserForm";

function EditUserModel(props) {
  const { t } = useTranslation();
  const { updateUserTenantFunction, user } = indexStore();

  const handleSubmitEditUser = (values) => {
    const submitValues = {
      ...values,
      id: user.id,
      userNameTenant: values.phone,
      isAdmin: 0,
    };
    updateUserTenantFunction(submitValues);
  };
  return (
    <MainDialog
      trigger={props.trigger}
      title={`${t("gloabl.edit")} ${t(`gloabl.user`)}`}
    >
      <UserForm
        onSubmit={handleSubmitEditUser}
        editMode={true}
        datasource={user}
      />
    </MainDialog>
  );
}

export default EditUserModel;
