import { axiosInstance } from "../axios-instance";

const itemsRoot = "product";
const itemsServices = {
  getItems: (branchId) =>
    axiosInstance.get(`${itemsRoot}/getProduct/${branchId}`),
  getItem: (productId) => axiosInstance.get(`${itemsRoot}/show/${productId}`),
  insertItem: (payload) =>
    axiosInstance.postForm(`${itemsRoot}/store`, payload),
  updateItem: (payload) =>
    axiosInstance.postForm(`${itemsRoot}/update/${payload.id}`, payload),
  deleteItem: (id) => axiosInstance.delete(`${itemsRoot}/delete/${id}`),
  getItemsByCatId: (id) =>
    axiosInstance.get(`${itemsRoot}/getsortPrdouct/${id}`),
  sortItems: (arr) => axiosInstance.post(`${itemsRoot}/sortPrdouct`, arr),
  ////////////////////////////////////////////////////////////////////////
};
export default itemsServices;
