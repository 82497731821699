import { ChartBart } from "src/Components/Charts/ChartBart";
import { VerticalChart } from "src/Components/Charts/VerticalChart";
import { DatePickerWithRange } from "src/Components/DatePicker/DatePickerRange";
import { Button } from "src/Components/ui/button";
import {
  formatDate,
  getFirstDayOfMonth,
  getToday,
} from "src/Components/utils/FormatDate";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { indexStore } from "src/store/indexStore";
import CitiesAndCountries from "./InfoStatistics/CitiesAndCountries/CitiesAndCountries";

function BranchStatistics(props) {
  const {
    fetchBranchVistsAction,
    BranchVistsArray,
    BranchVistsLoading,
    branchName_ar,
    branchName_en,
    BranchSumNumVisit,
    BranchDate,
    lang,
    prdouctForBranch,
    nameLang,
  } = indexStore();
  const { t } = useTranslation();

  const { branchId } = useParams();

  const [bDate, setBDate] = React.useState({
    from: getFirstDayOfMonth(),
    to: getToday(),
  });
  const handelSubmitBranch = () => {
    fetchBranchVistsAction({
      name: "branch",
      id: branchId,
      startDate: formatDate(bDate.from),
      endDate: formatDate(bDate.to),
    });
  };
  return (
    <div className="flex flex-col  justify-between gap-5 w-full">
      <h2 className="text-xl font-bold">
        {t("gloabl.branch")} {t("gloabl.statistics")}
      </h2>
      <div className="flex flex-wrap gap-4 w-full items-center">
        <DatePickerWithRange date={bDate} setDate={setBDate} />
        <Button onClick={() => handelSubmitBranch()}>
          {t("gloabl.apply")}
        </Button>
      </div>
      <div className="flex flex-col flex-wrap  justify-between gap-5 w-full">
        <ChartBart
          layout="horizontal"
          date={BranchDate}
          classNameCard="w-full md:w-2/3 justify-between text-xl"
          data={BranchVistsArray}
          title={lang === "ar" ? branchName_ar : branchName_en}
          sum={BranchSumNumVisit}
          name={nameLang}
        />
        <VerticalChart
          data={prdouctForBranch}
          classNameCard="w-full   justify-between text-xl"
          date={BranchDate}
          title={`${t("dashbord.items")} ${t("gloabl.visits")} ${
            lang === "ar" ? branchName_ar : branchName_en
          }`}
          name={nameLang}
        />
        <CitiesAndCountries />
      </div>
    </div>
  );
}

export default BranchStatistics;
