import React from "react";
import Iterator from "../utils/Iterator";
import { LinkItem, LogOutButton } from "./LinkItem";
import LangSelect from "../langSelect/langSelect";
import { DarkModeToggle } from "../langSelect/DarkModeToggle";

export default function Sidebar(props) {
  return (
    <aside className="hidden border-r bg-background md:block h-full relative">
      <div className="flex flex-col gap-2 sticky top-0 h-screen max-h-screen overflow-auto">
        <div className="flex justify-center h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
          {localStorage.getItem("restName")?.toUpperCase()}
        </div>
        <div className="flex-1 w-full relative">
          <nav className="w-full flex flex-col gap-2 items-start px-2 text-sm font-medium lg:px-4">
            <Iterator
              data={props.siderLinks}
              render={(e) => <LinkItem item={e} />}
            />
            <LangSelect />
            <DarkModeToggle />
            <LogOutButton />
          </nav>
        </div>
      </div>
    </aside>
  );
}
