import React from "react";
import EditUserModel from "../pages/users/EditUserModel";
import { CiEdit } from "react-icons/ci";
import { AlertDialogs } from "../Dialgos/Alter-Daylogs";
import { MdOutlineDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { indexStore } from "src/store/indexStore";
import { LuUserCircle } from "react-icons/lu";
import { Skeleton } from "../ui/skeleton";

export function UserCard(props) {
  const { t } = useTranslation();
  const { restaurants, nameLang, setUser, deleteUserTenantFunction } =
    indexStore();
  const editUser = (e) => {
    setUser(e);
  };
  const onDelete = (id) => {
    deleteUserTenantFunction(id);
  };
  const restaurant = restaurants.find((e) => e.id == props.idRestaurant);

  return (
    <div
      key={props.id}
      className={`flex shadow-card-shadow rounded-md border p-3 gap-5  flex-col `}
    >
      <div className="flex justify-center">
        <LuUserCircle className="text-8xl  text-center" />
      </div>
      <div className="text-center text-lg font-semibold">
        {props.userNameTenant}
      </div>
      <div className="text-center  font-semibold">
        {props && props.idRestaurant && restaurant?.[nameLang]}
      </div>

      <div className="flex justify-between items-center">
        <EditUserModel
          trigger={
            <CiEdit
              className="text-xl cursor-pointer"
              onClick={() => editUser(props)}
            />
          }
        />

        <AlertDialogs
          trigger={
            <MdOutlineDelete className="text-xl cursor-pointer text-red-600 " />
          }
          variant="icon"
          onClickOk={() => onDelete(props.id)}
          description={<spn>{t("gloabl.deleteMessage")}</spn>}
          title={`${t("gloabl.delete")} ${t(`gloabl.user`)}`}
        />
      </div>
    </div>
  );
}

export const UserCardLoading = () => (
  <div
    className={`flex shadow-card-shadow rounded-md border p-3 gap-5  flex-col `}
  >
    <div className="flex justify-center">
      <LuUserCircle className="text-8xl  text-center" />
    </div>
    <div className="flex justify-center items-center flex-col gap-5 ">
      <Skeleton className={"w-32 h-5"} />
      <Skeleton className={"w-32 h-5"} />
    </div>
    <div className="flex justify-between items-center">
      <Skeleton className={"w-5 h-5"} />
      <Skeleton className={"w-5 h-5"} />
    </div>
  </div>
);
