import React, { useState } from "react";
import { Input } from "../ui/input";
import { Eye, EyeOff } from "lucide-react";
import { useController } from "react-hook-form";

export const InputPassword = ({ name, control, ...props }) => {
  const [isView, setIsView] = useState();
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    defaultValue: "", // Set an empty array as the default value
  });

  return (
    <div className="relative flex items-center " dir="ltr">
      <Input
        onChange={field.onChange}
        type={isView ? "text" : "password"}
        id="password"
        placeholder=" "
        on
        value={field.value}
      />
      {isView ? (
        <Eye
          className="absolute right-4 top-2 z-10 cursor-pointer text-gray-500"
          onClick={() => {
            setIsView(!isView);
          }}
        />
      ) : (
        <EyeOff
          className="absolute right-4 top-2 z-10 cursor-pointer text-gray-500"
          onClick={() => setIsView(!isView)}
        />
      )}
    </div>
  );
};
