export const localeAr = {
  gloabl: {
    add: "إضافة",
    edit: "تعديل",
    name: "الاسم",
    itemName: "اسم المادة",
    arabicName: "الاسم العربي",
    englishName: "الاسم الاجنبي",
    address: "العنوان",
    arabicAddress: "العنوان العربي",
    englishAddress: "العنوان الاجنبي",
    image: "الصورة ",
    anImage: "صورة",
    logo: "الشعار",
    menu: "القائمة",
    category: "الفئة",
    option: "خيار",
    url: "الرابط",
    cancel: "إلغاء",
    save: "حفظ",
    delete: "حذف",
    upload: "تحميل",
    price: "السعر",
    price_offer: "سعر الخصم",
    offers: "العروض",
    itemProperties: "خصائص المادة",
    description_ar: "الوصف باللغة العربية ",
    description: "الوصف",
    description_en: "الوصف باللغة الاجنبية ",
    hidden: "مخفي",
    unavailable: "غير متوفر",
    popularItem: " شائع",
    newItem: " جديد",
    subOptions: "خيارات فرعية ",
    options_of: "خيارات",
    sub_option: "خيار فرعي",
    list: "قائمة",
    pluse: "زيادة",
    mines: "نقصان",
    change_price: "تغير السعر",
    search_to_select: "ابحث للتحديد",
    LogOut: "تسجيل خروج",
    Login: "تسجيل دخول",
    Username: "اسم المستخدم",
    user: "المستخدم",
    users: "المستخدمين",
    Password: "كلمة المرور",
    chooseColor: "اختيار اللون",
    colorsMenu: "الوان قائمة الطعام",
    primary: "اللون الرئيسي",
    onPrimary: "لون الخط فوق الرئيسي ",
    secondary: "اللون  الثانوي",
    onSecondary: "لون الخط فوق الثانوي",
    enable: "لون العناصر المفعلة",
    disable: "لون العناصر الغير مفعلة",
    background: "لون الخلفية ",
    onBackground: "لون الخط ",
    socialMedia: "وسائل التواصل الاجتماعي",
    branch: "فرع",
    phone: "رقم الهاتف",
    mobile: "رقم الهاتف المحمول ",
    branches: "الافرع",
    qrCode: "رمز الاستجابة السريعة",
    restaurants: "المطاعم",
    restaurant: "مطعم",
    Active: "نشط",
    restaurantName: "اسم المطعم ",
    subOptionValue: "القيمة",
    yes: "نعم",
    no: "لا",
    deleteMessage:
      "سيتم حذف جميع العناصر المتعلقة بهذا العنصر ,هل تريد الحذف ؟",
    deleteMessage2: "هل تريد حذف هذا العنصر ؟",
    roulete: "الصلاحيات",
    domin: "النطاق",
    features: "الخصائص",
    select: "تحديد",
    search: "بحث",
    item: "مادة ",
    type: "نوع",
    darkMode: "الوضع الليلي",
    defaultImage: "الصورة الافتراضية",
    sumVisits: "مجموع الزيارات",
    apply: "تطبيق",
    sum: "مجموع",
    from: "من",
    to: "الي",
    veiw: "عرض",
    visit: "زيارة",
    visits: "الزيارات",
    statistics: "احصائيات",
    total: "المجموع",
    cities: "المدن",
    countries: "الدول",
    systems: "الانظمة",
    OS: "نظام التشغيل",
    city: "مدينة",
    country: "دولة",
    scan: "مسح",
    top: "الاعلى",
    oSystem: "نظام التشغيل",
  },
  dashbord: {
    home: "الرئيسية",
    menus: "القوائم",
    categories: "الفئات",
    items: "المواد",
    options: "الخيارات",
    sort: "ترتيب",
    branches: "الافرع",
    restaurants: "المطاعم",
    users: "المستخدمين",
  },
  userTypes: {
    0: "مدير اساسي",
    1: "مدير",
    2: "مستخدم",
  },
  errorMessage: {
    fieldRequired: "مطلوب ",
    fillAll: "الرجاء ملئ جميع الحقول المطلوبة",
    fieldCharsLength: ` يجب ان يكون حرفين  على الاقل`,
    startwith: " يجب ان يبدأ ب",
    worngLogin: "اسم المستخدم او كلمة المرور غير صحيحة",
    serverError: "حدث خطأ في الخادم",
    103: "هذا العنصر موجود بالفعل",
    noData: "لا يوجد بيانات",
  },
  messages: {
    imageNotSelected:
      "في حال عدم تحديد صورة، سيتم استخدام صورة آخر منتج تمت إضافته إلى القائمة",
    store: "تم الحفظ بنجاح",
    update: "تم التحديث بنجاح",
    delete: "تم الحذف بنجاح",
    add: "تم الاضافة بنجاح",
    not_found: "البيانات غير موجودة",
  },
};
