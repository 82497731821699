import { t } from "i18next";
import { useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  XAxis,
  YAxis,
} from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "src/Components/ui/card";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "src/Components/ui/chart";

const chartConfig = {
  sumNumVisit: {
    label: t("gloabl.visits"),
    // label: "vvvvvv",
    color: "hsl(var(--chart-2))",
  },
};

export function VerticalChart(props) {
  const itemsPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(props.data.length / itemsPerPage);

  const paginatedData = props.data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Card className={props.classNameCard}>
      <CardHeader>
        <CardTitle>{props.title}</CardTitle>
        <CardDescription>
          {t("gloabl.from")} {props.date.from} {t("gloabl.to")} {props.date.to}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer className="w-full" config={chartConfig}>
          <BarChart
            accessibilityLayer
            data={paginatedData}
            layout="vertical"
            margin={{ right: 16 }}
          >
            <CartesianGrid horizontal={false} className="w-full" />
            <YAxis
              dataKey={props.name}
              type="category"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => value.slice(5, 10)}
              hide
            />
            <XAxis dataKey="sumNumVisit" type="number" tickMargin={10} />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="line" />}
            />
            <Bar
              dataKey="sumNumVisit"
              layout="horizontal"
              fill="var(--color-sumNumVisit)"
              radius={4}
            >
              <LabelList
                dataKey={"name_en"}
                position="insideLeft"
                offset={8}
                className="fill-foreground hidden md:block"
                fontSize={12}
              />
              <LabelList
                dataKey="sumNumVisit"
                position="right"
                offset={8}
                className="fill-foreground"
                fontSize={12}
              />
            </Bar>
          </BarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter>
        <div className="pagination-controls">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              disabled={currentPage === index + 1}
              className={`px-2 py-1 mx-1 text-xs sm:text-sm  ${
                currentPage === index + 1
                  ? "bg-primary text-primary-foreground"
                  : ""
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </CardFooter>
    </Card>
  );
}
