import { t } from "i18next";

export const countriesColumns = (total) => [
  {
    accessorKey: "index",
    header: "#",
    cell: ({ table, row }) => {
      return (
        <div className="font-medium">
          {table.getSortedRowModel().rows.indexOf(row) + 1}
        </div>
      );
    },
  },
  {
    accessorKey: "name",
    header: t("gloabl.countries"),
  },
  {
    accessorKey: "visit",
    header: t("gloabl.scan"),
  },
  {
    accessorKey: "",
    header: "%",
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue("visit"));

      const formatted = total
        ? `${((amount / total) * 100).toFixed(2)}%`
        : `${(amount * 100).toFixed(2)}%`;

      return <div className=" font-medium">{formatted}</div>;
    },
  },
];

export const citiesColumns = (total) => [
  {
    accessorKey: "index",
    header: "#",
    cell: ({ table, row }) => {
      return (
        <div className="font-medium">
          {table.getSortedRowModel().rows.indexOf(row) + 1}
        </div>
      );
    },
  },
  {
    accessorKey: "name",
    header: t("gloabl.cities"),
  },
  {
    accessorKey: "visit",
    header: t("gloabl.scan"),
  },
  {
    accessorKey: "",
    header: "%",
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue("visit"));

      const formatted = total
        ? `${((amount / total) * 100).toFixed(2)}%`
        : `${(amount * 100).toFixed(2)}%`;

      return <div className=" font-medium">{formatted}</div>;
    },
  },
];

export const oSysteamsColumns = (total) => [
  {
    accessorKey: "index",
    header: "#",
    cell: ({ table, row }) => {
      return (
        <div className="font-medium">
          {table.getSortedRowModel().rows.indexOf(row) + 1}
        </div>
      );
    },
  },
  {
    accessorKey: "name",
    header: "OS",
  },
  {
    accessorKey: "visit",
    header: t("gloabl.scan"),
  },
  {
    accessorKey: "",
    header: "%",
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue("visit"));

      const formatted = total
        ? `${((amount / total) * 100).toFixed(2)}%`
        : `${(amount * 100).toFixed(2)}%`;

      return <div className=" font-medium">{formatted}</div>;
    },
  },
];
