import { MyTabs } from "src/Components/Tabs/MyTabs";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormField } from "../../ui/form";
import FormFieldItem from "src/common/form/FormFieldItem";
import { Input } from "../../ui/input";
function MenuTabs(props) {
  const { t } = useTranslation();

  return (
    <MyTabs
      tabRender={(e) => {
        return (
          <FormField
            required
            control={props.form.control}
            name={e === "ar" ? "name_ar" : "name_en"}
            render={({ field }) => (
              <FormFieldItem
                required
                form={props.form}
                label={t("gloabl.name")}
              >
                <Input {...field} />
              </FormFieldItem>
            )}
          />
        );
      }}
    ></MyTabs>
  );
}

export default MenuTabs;
