import { axiosInstance } from "../axios-instance";

const branch = "branch";

const BranchesServices = {
  getBranchs: () => axiosInstance.get(`${branch}/getBranch`),
  getBranch: (branchId) => axiosInstance.get(`${branch}/show/${branchId}`),
  storeBranch: (payload) => axiosInstance.postForm(`${branch}/store`, payload),
  updateBranch: (payload) =>
    axiosInstance.postForm(`${branch}/update/${payload.id}`, payload),
  DeleteBranch: (id) => axiosInstance.delete(`${branch}/delete/${id}`),
};

export default BranchesServices;
