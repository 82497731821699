import itemsServices from "src/api-services/items-services";
import StatisticsServices from "src/api-services/statistics-services";

export const createStatisticsSlice = (set) => ({
  BranchVistsArray: [],
  BranchSumNumVisit: 0,
  BranchVistsLoading: false,
  branchName_ar: "",
  branchName_en: "",
  BranchDate: { from: "", to: "" },
  prdouctForBranch: [],
  ////////////////////////////////////////
  ProductVistsArray: [],
  ProductSumNumVisit: 0,
  ProductVistsLoading: false,
  ProductName_ar: "",
  ProductName_en: "",
  productIdStatistics: "",
  products: [],
  ProductDate: { from: "", to: "" },
  ////////////////////////////////////////
  MenuVistsArray: [],
  MenuSumNumVisit: 0,
  MenuVistsLoading: false,
  MenuName_ar: "",
  MenuName_en: "",
  MenuIdStatistics: "",
  MenuDate: { from: "", to: "" },
  ////////////////////////////////////////
  CategoryVistsArray: [],
  CategorySumNumVisit: 0,
  CategoryVistsLoading: false,
  CategoryName_ar: "",
  CategoryName_en: "",
  CategoryIdStatistics: "",
  CategoryDate: { from: "", to: "" },

  ////////////// Info statics ////////////////////////////////
  cities: [],
  countries: [],
  oSysteam: [],
  infoTotal: 0,
  ////////////// Info statics ////////////////////////////////

  ///////////////////get branch  statistics //////////////////////////

  fetchBranchVistsAction: async (payload) => {
    set((state) => ({
      ...state,
      BranchVistsArray: [],
      BranchVistsLoading: true,
      BranchSumNumVisit: 0,
    }));
    try {
      const response = await StatisticsServices.getVisits(payload);
      const products = response.data.data.prdouctForBranch
        .filter((item) => item[0].sumNumVisit > 0) // Filter items where the first element's sumNumVisit > 0
        .map((item) => item[0]); // Return the first element of each filtered item

      set((state) => ({
        ...state,
        BranchDate: { from: payload.startDate, to: payload.endDate },
        BranchVistsArray: response.data.data.arrayOfVisit,
        BranchSumNumVisit: response.data.data.sumNumVisit,
        BranchVistsLoading: false,
        branchName_ar: response.data.data.name_ar,
        branchName_en: response.data.data.name_en,
        prdouctForBranch: products,
        cities: response.data.data.info.city,
        countries: response.data.data.info.country,
        oSysteam: response.data.data.info.system,
        infoTotal: response.data.data.info.system_total,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        BranchVistsLoading: false,
      }));
    }
  },
  ///////////////////get product  statistics //////////////////////////

  fetchProductVistsAction: async (payload) => {
    set((state) => ({
      ...state,
      ProductVistsArray: [],
      ProductVistsLoading: true,
      ProductSumNumVisit: 0,
    }));
    try {
      const response = await StatisticsServices.getVisits(payload);
      set((state) => ({
        ...state,
        ProductDate: { from: payload.startDate, to: payload.endDate },
        ProductVistsArray: response.data.data.arrayOfVisit,
        ProductSumNumVisit: response.data.data.sumNumVisit,
        ProductVistsLoading: false,
        ProductName_ar: response.data.data.name_ar,
        ProductName_en: response.data.data.name_en,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        ProductVistsLoading: false,
      }));
    }
  },

  ///////////////////get menu  statistics //////////////////////////
  fetchMenuVistsAction: async (payload) => {
    set((state) => ({
      ...state,
      MenuVistsArray: [],
      MenuVistsLoading: true,
      MenuSumNumVisit: 0,
    }));
    try {
      const response = await StatisticsServices.getVisits(payload);
      set((state) => ({
        ...state,
        MenuDate: { from: payload.startDate, to: payload.endDate },
        MenuVistsArray: response.data.data.arrayOfVisit,
        MenuSumNumVisit: response.data.data.sumNumVisit,
        MenuVistsLoading: false,
        MenuName_ar: response.data.data.name_ar,
        MenuName_en: response.data.data.name_en,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        MenuVistsLoading: false,
      }));
    }
  },
  ///////////////////get category  statistics //////////////////////////
  fetchCategoryVistsAction: async (payload) => {
    set((state) => ({
      ...state,
      CategoryVistsArray: [],
      CategoryVistsLoading: true,
      CategorySumNumVisit: 0,
    }));
    try {
      const response = await StatisticsServices.getVisits(payload);
      set((state) => ({
        ...state,
        CategoryDate: { from: payload.startDate, to: payload.endDate },
        CategoryVistsArray: response.data.data.arrayOfVisit,
        CategorySumNumVisit: response.data.data.sumNumVisit,
        CategoryVistsLoading: false,
        CategoryName_ar: response.data.data.name_ar,
        CategoryName_en: response.data.data.name_en,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        CategoryVistsLoading: false,
      }));
    }
  },
  ////////////////// fetch all products //////////////////////////
  fetchAllProducts: async (id) => {
    set((state) => ({
      ...state,
    }));
    try {
      const response = await itemsServices.getItems(id);
      set((state) => ({
        ...state,
        products: response.data.data,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
      }));
    }
  },

  setProductIdStatistics: (id) => {
    set((state) => ({
      ...state,
      productIdStatistics: id,
    }));
  },
  setMenuIdStatistics: (id) => {
    set((state) => ({
      ...state,
      MenuIdStatistics: id,
    }));
  },
  setCategoryIdStatistics: (id) => {
    set((state) => ({
      ...state,
      CategoryIdStatistics: id,
    }));
  },
  restDataStaticsFunction: () => {
    set((state) => ({
      ...state,
      BranchVistsArray: [],
      BranchSumNumVisit: 0,
      BranchVistsLoading: false,
      branchName_ar: "",
      branchName_en: "",
      BranchDate: {},
      prdouctForBranch: [],
      ////////////////////////////////////////
      ProductVistsArray: [],
      ProductSumNumVisit: 0,
      ProductVistsLoading: false,
      ProductName_ar: "",
      ProductName_en: "",
      productIdStatistics: "",
      products: [],
      ////////////////////////////
      MenuVistsArray: [],
      MenuSumNumVisit: 0,
      MenuVistsLoading: false,
      MenuName_ar: "",
      MenuName_en: "",
      MenuIdStatistics: "",
      MenuDate: { from: "", to: "" },
      ////////////////////////////////////////
      CategoryVistsArray: [],
      CategorySumNumVisit: 0,
      CategoryVistsLoading: false,
      CategoryName_ar: "",
      CategoryName_en: "",
      CategoryIdStatistics: "",
      CategoryDate: { from: "", to: "" },
      cities: [],
      countries: [],
      oSysteam: [],
      infoTotal: 0,
    }));
  },
});
