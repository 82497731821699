import { ComboboxForm } from "src/Components/selectbox/ComboBoxForm";
import { Button } from "src/Components/ui/button";
import { DialogClose } from "src/Components/ui/dialog";
import { Form, FormField } from "src/Components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import FormFieldItem from "src/common/form/FormFieldItem";
import { indexStore } from "src/store/indexStore";
import { z } from "zod";
import { Input } from "src/Components/ui/input";
import { SelectBoxForm } from "src/Components/selectbox/SelectBoxForm";
import { userTypes } from "src/enums/usersTpes";

import { InputPassword } from "src/Components/utils/InputPassword";

function UserForm(props) {
  const { nameLang, restaurants } = indexStore();
  const { t } = useTranslation();
  const roles = [
    {
      id: userTypes.ADMIN,
      name: t(`userTypes.${userTypes.ADMIN}`),
    },
    { id: userTypes.USER, name: t(`userTypes.${userTypes.USER}`) },
  ];
  const formSchema = z.object({
    phone: z
      .string({
        required_error: `${t("gloabl.Username")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.Username")} ${t(
          "errorMessage.fieldCharsLength"
        )}.`,
      }),
    userPassTenant: z
      .string({
        required_error: `${t("gloabl.Password")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.Password")} ${t(
          "errorMessage.fieldCharsLength"
        )}.`,
      }),
    idRestaurant: z.any({
      required_error: `${t("gloabl.name")} ${t("errorMessage.fieldRequired")}.`,
    }),
    sumPermessions: z.any({
      required_error: `${t("gloabl.menu")} ${t("errorMessage.fieldRequired")}.`,
    }),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),

    defaultValues: {
      ...props.datasource,
    },
  });
  const dialogCloseRef = useRef();

  const onSubmit = (values) => {
    const submitValues = {
      ...values,
    };
    console.log(submitValues);

    props.onSubmit(submitValues);
    if (dialogCloseRef.current) {
      dialogCloseRef.current.click();
    }
  };
  const onErorr = (errors) => {
    toast.error(t("errorMessage.fillAll"));
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, onErorr)}
        className="space-y-8"
      >
        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => {
            return (
              <FormFieldItem required label={t("gloabl.Username")} form={form}>
                <Input {...field} />
              </FormFieldItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="userPassTenant"
          render={({ field }) => {
            return (
              <FormFieldItem required label={t("gloabl.Password")} form={form}>
                <InputPassword
                  name={"userPassTenant"}
                  control={form.control}
                  {...field}
                />
              </FormFieldItem>
            );
          }}
        />

        <FormField
          control={form.control}
          name="idRestaurant"
          render={({ field }) => {
            return (
              <FormFieldItem
                required
                label={t("gloabl.restaurant")}
                form={form}
              >
                <ComboboxForm
                  data={restaurants}
                  {...field}
                  placeholder={
                    t("gloabl.select") + " " + t("gloabl.restaurant")
                  }
                  lable={nameLang}
                />
              </FormFieldItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="sumPermessions"
          render={({ field }) => {
            return (
              <FormFieldItem required label={t("gloabl.roulete")} form={form}>
                <SelectBoxForm
                  data={roles}
                  {...field}
                  placeholder={t("gloabl.select") + " " + t("gloabl.roulete")}
                  label={"name"}
                  valueKey={"id"}
                />
              </FormFieldItem>
            );
          }}
        />
        <div className="flex items-center gap-3 ">
          <DialogClose asChild>
            <Button type="button" variant="outline">
              {t("gloabl.cancel")}
            </Button>
          </DialogClose>
          <DialogClose ref={dialogCloseRef} />
          <Button type="submit">{t("gloabl.save")}</Button>
        </div>
      </form>
    </Form>
  );
}

export default UserForm;
