import React, { useEffect, useState } from "react";
import ColorPicker from "./ColorPicker";
import { indexStore } from "../../store/indexStore";
import Iterator from "../utils/Iterator";
import { useTranslation } from "react-i18next";
import Visibility from "../utils/Visibility";
import { Skeleton } from "../ui/skeleton";

function ColorsPicker() {
  const { t } = useTranslation();
  const { style, changeColorsFunction, storeColorsFunction, styleLoading } =
    indexStore();
  const [styleColor, setStyleColor] = useState({});

  const onChange = (value, e) => {
    const updatedColors = {
      ...style.colors,
      [e]: value,
    };

    const data = {
      id: style.id,
      restaurant_id: style.restaurant_id || localStorage.getItem("resId"),
      colors: updatedColors,
    };
    if (style.restaurant_id) {
      changeColorsFunction(data);
    } else {
      storeColorsFunction(data);
    }
  };

  const renderColorPickers = (e, i) => {
    return (
      <div className="flex flex-col  gap-3   items-center">
        <p key={e.id}>{t(`gloabl.${e}`)}</p>
        <Visibility visible={styleLoading}>
          <Skeleton className={"w-8 h-8"} />
        </Visibility>
        <Visibility visible={!styleLoading}>
          <ColorPicker
            color={style.colors[e]}
            onChange={(value) => onChange(value, e)}
          />
        </Visibility>
      </div>
    );
  };
  useEffect(() => {
    const filteredStyleColors = Object.keys(style.colors).reduce((acc, key) => {
      if (key !== "onSecondary" && key !== "enable") {
        acc[key] = style.colors[key];
      }
      return acc;
    }, {});
    setStyleColor(filteredStyleColors);
  }, [style]);

  delete style.onSecondary;
  delete style.enable;
  return (
    <div className="flex flex-col gap-5">
      <h2 className="text-lg font-bold">{t("gloabl.colorsMenu")}</h2>

      <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
        <Iterator data={Object.keys(styleColor)} render={renderColorPickers} />
      </div>
    </div>
  );
}

export default ColorsPicker;
