import React from "react";
import { useTranslation } from "react-i18next";
import { indexStore } from "src/store/indexStore";
import { CiEdit } from "react-icons/ci";
import { AlertDialogs } from "../Dialgos/Alter-Daylogs";
import { MdOutlineDelete } from "react-icons/md";
import EditOptionModel from "../pages/options/EditOptionModel";
import AddSubOptionModel from "../pages/options/subOption/AddSubOptionModel";
import { IoIosAdd } from "react-icons/io";
import Iterator from "../utils/Iterator";
import SubOptionRow from ".//SubOptionRow";
import { Skeleton } from "../ui/skeleton";

export function OptionCard(props) {
  const { t } = useTranslation();
  const { deleteOptionFunction, setOption, nameLang } = indexStore();
  const onDelete = (id) => {
    deleteOptionFunction(id);
  };

  const editOption = (element) => {
    setOption(element);
  };

  return (
    <div
      key={props.id}
      className={`flex  shadow-card-shadow rounded-md border p-3 gap-5  flex-col `}
    >
      <div className="text-center  text-lg font-semibold">
        {props[nameLang]}
      </div>
      <div className="border rounded-md p-2">
        <Iterator
          data={props.subOption}
          render={(e) => <SubOptionRow {...e} />}
        />
      </div>

      <div className="flex justify-between items-center">
        <EditOptionModel
          trigger={
            <CiEdit
              className="text-xl cursor-pointer"
              onClick={() => editOption(props)}
            />
          }
        />
        <AddSubOptionModel
          option_id={props.id}
          trigger={
            <div className="flex cursor-pointer items-center">
              <IoIosAdd className="text-xl cursor-pointer" />
              <span className="  ">{t(`gloabl.sub_option`)}</span>
            </div>
          }
        />
        <AlertDialogs
          trigger={
            <MdOutlineDelete className="text-xl cursor-pointer text-red-600 " />
          }
          variant="icon"
          onClickOk={() => onDelete(props.id)}
          description={<spn>{t("gloabl.deleteMessage")}</spn>}
          title={`${t("gloabl.delete")} ${t(`gloabl.option`)}`}
        />
      </div>
    </div>
  );
}

export const OptionCardLoading = () => (
  <div
    className={`flex  shadow-card-shadow rounded-md border p-3 gap-5  flex-col `}
  >
    <div className="flex justify-center">
      <Skeleton className={"w-32 h-5"} />
    </div>
    <div className="border flex flex-col gap-2 rounded-md p-2">
      <Iterator
        data={[1, 2, 3]}
        render={(e) => <Skeleton className={"w-full h-5"} />}
      />
    </div>

    <div className="flex justify-between items-center">
      <Skeleton className="w-5 h-5" />
      <Skeleton className="w-8 h-5" />
      <Skeleton className="w-5 h-5" />
    </div>
  </div>
);
