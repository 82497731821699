import React from "react";
import { useTranslation } from "react-i18next";

import { indexStore } from "src/store/indexStore";
import EditCategoryModel from "../pages/categories/EditCategoryModel";
import { CiEdit } from "react-icons/ci";
import { AlertDialogs } from "../Dialgos/Alter-Daylogs";
import { MdOutlineDelete } from "react-icons/md";
import { Skeleton } from "../ui/skeleton";

export function CategoryCard(props) {
  const { t } = useTranslation();
  const { deleteCategoryAction, setCategory, menus, nameLang } = indexStore();
  const onDeleteMenu = (id) => {
    deleteCategoryAction(id);
  };
  const editCategory = (element) => {
    setCategory(element);
  };
  const menu =
    menus &&
    menus.length > 0 &&
    menus.filter((menu) => menu.id == props.menu_id)[0];
  return (
    <div
      key={props.id}
      className={`flex shadow-card-shadow rounded-md border p-3 gap-2  flex-col `}
    >
      <div className="text-center text-lg font-semibold">{props[nameLang]}</div>
      <div className="text-center text-lg ">{menu[nameLang]}</div>
      <div className="flex justify-between items-center">
        <EditCategoryModel
          trigger={
            <CiEdit
              className="text-xl cursor-pointer"
              onClick={() => editCategory(props)}
            />
          }
        />

        <AlertDialogs
          trigger={
            <MdOutlineDelete className="text-xl cursor-pointer text-red-600 " />
          }
          variant="icon"
          onClickOk={() => onDeleteMenu(props.id)}
          description={<spn>{t("gloabl.deleteMessage")}</spn>}
          title={`${t("gloabl.delete")} ${t(`gloabl.category`)}`}
        />
      </div>
    </div>
  );
}
export const CategoryCardLoading = () => (
  <div
    className={`flex shadow-card-shadow rounded-md border p-3 gap-2  flex-col `}
  >
    <div className="flex justify-center gap-2 flex-col items-center">
      <Skeleton className={"w-16 h-4"} />
      <Skeleton className={"w-16 h-4"} />
    </div>
    <div className="flex justify-between items-center">
      <Skeleton className={"w-5 h-5"} />
      <Skeleton className={"w-5 h-5"} />
    </div>
  </div>
);
