import { ChartBart } from "src/Components/Charts/ChartBart";
import { DatePickerWithRange } from "src/Components/DatePicker/DatePickerRange";
import { Combobox } from "src/Components/selectbox/ComboBox";
import { Button } from "src/Components/ui/button";
import {
  formatDate,
  getFirstDayOfMonth,
  getToday,
} from "src/Components/utils/FormatDate";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { indexStore } from "src/store/indexStore";
import { getUniqueCategories } from "src/Components/utils/UtilsFunctions";
function CategoryStatistics() {
  const {
    lang,
    nameLang,
    CategoryVistsArray,
    CategorySumNumVisit,
    CategoryVistsLoading,
    CategoryName_ar,
    CategoryName_en,
    CategoryIdStatistics,
    CategoryDate,
    categories,
    fetchCategoryVistsAction,
    setCategoryIdStatistics,
    menus
  } = indexStore();
  const { t } = useTranslation();

  const [cDate, setCDate] = React.useState({
    from: getFirstDayOfMonth(),
    to: getToday(),
  });
  const handelSelectProduct = (value) => {
    setCategoryIdStatistics(value);
  };

  const handelSubmitProduct = () => {
    if (CategoryIdStatistics) {
      fetchCategoryVistsAction({
        name: "category",
        id: CategoryIdStatistics,
        startDate: formatDate(cDate.from),
        endDate: formatDate(cDate.to),
      });
    } else {
      toast.error(t("gloabl.select") + " " + t("gloabl.category"));
    }
  };
  const uniqueCategories = getUniqueCategories(categories, menus, nameLang);
  

  return (
    <div className="flex flex-col   justify-between gap-5 w-full">
      <h2 className="text-xl font-bold">
        {t("gloabl.category")} {t("gloabl.statistics")}
      </h2>
      <div className="w-full flex flex-wrap gap-2">
        <Combobox
          placeholder={t("gloabl.select") + " " + t("gloabl.category")}
          data={uniqueCategories}
          value={CategoryIdStatistics || null}
          onChange={(value, option) => handelSelectProduct(value, option)}
          lable={"displayName"}
          />
        <DatePickerWithRange date={cDate} setDate={setCDate} />
        <Button onClick={() => handelSubmitProduct()}>
          {t("gloabl.apply")}
        </Button>
      </div>
      <ChartBart
        layout="horizontal"
        date={CategoryDate}
        classNameCard="w-full  justify-between text-xl"
        data={CategoryVistsArray}
        title={lang === "ar" ? CategoryName_ar : CategoryName_en}
        sum={CategorySumNumVisit}
        name={nameLang}
      />
    </div>
  );
}

export default CategoryStatistics;
