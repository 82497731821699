import React from "react";
import { Route, Routes } from "react-router-dom";
import RestaurantHomePage from "../pages/HomePages/RestaurantHomePage";
import Branches from "../pages/branchs/Branches";
import { ManiSliderLinks } from "../../common/Links/mainSliderLinks";
import Restaurants from "../pages/restaurant/Restaurants";
import { userTypes } from "../../enums/usersTpes";
import AddBranchModel from "../pages/branchs/AddBranchModel";
import EditBranchModel from "../pages/branchs/EditBranchModel";
import Sidebar from "../SiderLayout/SiderBar";
import MobileNav from "../SiderLayout/MobileNav";
import Users from "../pages/users/Users";

const MainLayout = (props) => {
  const mainLinks = ManiSliderLinks();
  const per = Number(localStorage.getItem("premssions"));
  const mainLinksFilter =
    per === userTypes.SUPERADMIN
      ? mainLinks
      : mainLinks.filter(
          (e) =>
            e.title !== "dashbord.restaurants" && e.title !== "dashbord.users"
        );
  return (
    <div className="flex flex-col min-h-screen w-full md:grid grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]">
      <div className="">
        <Sidebar siderLinks={mainLinksFilter} />
        <MobileNav siderLinks={mainLinksFilter} />
      </div>

      <div className="p-5 bg-backgroundConant h-full flex-1  ">
        <Routes>
          <Route path="/" element={<RestaurantHomePage />} />
          <Route path="/users" element={<Users />} />
          <Route path="/branches" element={<Branches />} />
          <Route path="/branch/add" element={<AddBranchModel />} />
          <Route path="/branch/edit/:id" element={<EditBranchModel />} />
          <Route path="/restaurants" element={<Restaurants />} />
        </Routes>
      </div>
    </div>
  );
};

export default MainLayout;
