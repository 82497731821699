import React, { useRef } from "react";
import { LuEye } from "react-icons/lu";

import Visibility from "../utils/Visibility";
import { useController } from "react-hook-form";
import { LuTrash2 } from "react-icons/lu";
const FileSvgDraw = () => {
  return (
    <>
      <svg
        className="w-14 h-14 mb-3 text-gray-500 dark:text-gray-400"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
        />
      </svg>
    </>
  );
};
function ImageSelected({ name, control, ...props }) {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    // defaultValue: "", // Set an empty array as the default value
  });
  const fileInputRef = useRef(null);
  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    field.onChange(file);
    console.log(URL.createObjectURL(file));

    props.setDeleteImage(0);
    props.setImage(URL.createObjectURL(file));
  };

  const handleDelete = () => {
    props.setImage(null);
    fileInputRef.current.value = "";
    props.setDeleteImage(1);
    // props.form.setValue("logo", "");
    field.onChange("");
  };
  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept="image/*"
        onChange={handleFileChange}
      />
      <div className="w-full">
        <div onClick={handleImageClick} className="cursor-pointer">
          <Visibility visible={props.image}>
            <div className="w-full flex items-center gap-4">
              <img
                src={props.image || "placeholder-image-url"}
                alt="+ Selected"
                className="max-h-[200px] aspect-square object-contain"
              />
              <div className="flex items-center gap-2 flex-col">
                <LuEye
                  className="text-2xl"
                  onClick={() =>
                    window.open(props.image, "_blank", "noopener,noreferrer")
                  }
                />

                <LuTrash2
                  className=" text-2xl hover:text-red-500"
                  onClick={handleDelete}
                />
              </div>
            </div>
          </Visibility>
          <Visibility visible={!props.image}>
            <FileSvgDraw />
          </Visibility>
        </div>
      </div>
    </div>
  );
}

export default ImageSelected;
