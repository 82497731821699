import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../../ui/button";
import { Form, FormField } from "../../../ui/form";
import { Input } from "../../../ui/input";
import FormFieldItem from "src/common/form/FormFieldItem";
import { useTranslation } from "react-i18next";
import { Switch } from "src/Components/ui/switch";
import { Label } from "../../../ui/label";
import { useEffect, useRef, useState } from "react";
import ImageSelected from "src/Components/Uploaders/ImageSelected";
import { IMG_URl } from "src/common/img_url";
import { DialogClose } from "src/Components/ui/dialog";
import { toast } from "react-toastify";
import RestaurantTabs from "../RestaurantTabs";
function ResturantForm(props) {
  const [deleteImage, setDeleteImage] = useState(0);
  const [deleteDefaultImage, setDeleteDefaultImage] = useState(0);
  const [logo, setLogo] = useState("");
  const [defaultImage, setDefaultImage] = useState("");
  const { t } = useTranslation();
  const dialogCloseRef = useRef(null);
  // make validation and form fields values conditionally
  const formSchema = z.object({
    domin: z
      .string({
        required_error: `${t("gloabl.domin")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.domin")} ${t("errorMessage.fieldCharsLength")}.`,
      }),
    isActive: z.any().optional(),
    name_ar: z
      .string({
        required_error: `${t("gloabl.name")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.name")} ${t("errorMessage.fieldCharsLength")}.`,
      }),
    name_en: z
      .string({
        required_error: `${t("gloabl.name")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.name")} ${t("errorMessage.fieldCharsLength")}.`,
      }),
    description_ar: z
      .string({
        required_error: `${t("gloabl.description")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.description")} ${t(
          "errorMessage.fieldCharsLength"
        )}.`,
      }),
    description_en: z
      .string({
        required_error: `${t("gloabl.description")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.description")} ${t(
          "errorMessage.fieldCharsLength"
        )}.`,
      }),
    logo: z.union([
      z.instanceof(File).refine((file) => file.size < 4 * 1024 * 1024, {
        message: "File size must be less than 4MB",
        required_error: `${t("gloabl.logo")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      }),
      z.string({
        required_error: `${t("gloabl.logo")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      }),
    ]),
    default_image: z
      .union([
        z
          .instanceof(File)
          .refine((file) => file.size < 4 * 1024 * 1024, {
            message: "File size must be less than 4MB",
            required_error: `${t("gloabl.logo")} ${t(
              "errorMessage.fieldRequired"
            )}.`,
          })
          .optional(),
        z
          .string({
            required_error: `${t("gloabl.logo")} ${t(
              "errorMessage.fieldRequired"
            )}.`,
          })
          .optional(),
      ])
      .optional(),
  });

  // 1. Define your form.
  const form = useForm({
    resolver: zodResolver(formSchema),

    defaultValues: {
      ...props.datasource,
    },
  });

  const onErorr = (errors) => {
    toast.error(t("errorMessage.fillAll"));
  };
  // 2. Define a submit handler.
  function onSubmit(values) {
    let submitValues = {
      ...values,
      deleteImage: deleteImage,
      domin: values.domin.replace(/ /g, "-"),
      isActive: values.isActive ? 1 : 0,
      logo: values.logo ? values.logo : "",
      currency: "AED",
    };
    // console.log(submitValues);
    props.onSubmit(submitValues);
    if (dialogCloseRef.current) {
      dialogCloseRef.current.click();
    }
  }
  useEffect(() => {
    if (props.editMode) {
      setLogo(props.datasource.logo ? IMG_URl + props.datasource.logo : "");
      setDefaultImage(
        props.datasource.logo ? IMG_URl + props.datasource.default_image : ""
      );
    }
  }, [props.editMode]);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, onErorr)}
        className="space-y-8"
      >
        <FormField
          control={form.control}
          name={"domin"}
          render={({ field }) => (
            <FormFieldItem required form={form} label={t("gloabl.domin")}>
              <Input {...field} />
            </FormFieldItem>
          )}
        />
        <RestaurantTabs form={form} className="bg-transparent " />

        <FormField
          control={form.control}
          name="isActive"
          render={({ field }) => (
            <FormFieldItem form={form}>
              <div className="flex items-center gap-2">
                <Switch
                  dir="ltr"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
                <Label htmlFor="airplane-mode">{t("gloabl.Active")}</Label>
              </div>
            </FormFieldItem>
          )}
        />
        <FormField
          control={form.control}
          name="logo"
          render={({ field }) => {
            return (
              <FormFieldItem label={t("gloabl.logo")} form={form}>
                <ImageSelected
                  form={form}
                  control={form.control}
                  name="logo"
                  {...field}
                  editMode={props.editMode}
                  image={logo}
                  setImage={setLogo}
                  setDeleteImage={setDeleteImage}
                />
              </FormFieldItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="default_image"
          render={({ field }) => {
            return (
              <FormFieldItem label={t("gloabl.defaultImage")} form={form}>
                <ImageSelected
                  form={form}
                  control={form.control}
                  name="default_image"
                  {...field}
                  editMode={props.editMode}
                  image={defaultImage}
                  setImage={setDefaultImage}
                  setDeleteImage={setDeleteDefaultImage}
                />
              </FormFieldItem>
            );
          }}
        />
        <div className="flex items-center gap-3 ">
          <DialogClose asChild>
            <Button type="button" variant="outline">
              {t("gloabl.cancel")}
            </Button>
          </DialogClose>
          <DialogClose ref={dialogCloseRef} />
          <Button type="submit">{t("gloabl.save")}</Button>
        </div>
      </form>
    </Form>
  );
}
export default ResturantForm;
