import { toast } from "react-toastify";
import i18n from "i18next";

const translate = (key) => {
  return i18n.t(key);
};

export async function showResponseMessage(api, method) {
  try {
    const response = await api;

    if (response?.status === 200) {
      toast.success(translate(`messages.${method}`));
    } else {
      // Handle different error cases
      if (response.status === 500) {
        // Authentication error
        toast.error(translate(`errorMessage.serverError`));
      } else if (response.status === 403) {
        // Unauthorized access error
        toast.error(translate(`errorMessage.unauthorizedError`)); // Corrected
      } else if (response.status === 400) {
        if (response.data.errorCode === 103) {
          toast.error(translate(`errorMessage.${response.data.errorCode}`));
        }
      } else {
        // Other status-dependent errors
        toast.error(translate(`errorMessage.genericError`)); // Corrected
      }
    }
    return response;
  } catch (error) {
    if (error.response.status === 422) {
      if (error.response.data.errorCode === 103) {
        toast.error(translate(`errorMessage.${error.response.data.errorCode}`));
      }
    } else if (error.response.status === 500) {
      toast.error(translate(`errorMessage.serverError`));
    } else if (
      error.response.status === 401 &&
      error.response.data.message === "Unauthenticated."
    ) {
      toast.error(translate(`errorMessage.serverError`));
    }
  }
}
