import { t } from "i18next";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  XAxis,
  YAxis,
} from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "src/Components/ui/card";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "src/Components/ui/chart";

const chartConfig = {
  numVisit: {
    label: "Visits",
    color: "hsl(var(--chart-1))",
  },
};

export function ChartBart(props) {
  return (
    <Card className={props.classNameCard}>
      <CardHeader>
        <CardTitle>{props.title}</CardTitle>
        <CardDescription>
          {t("gloabl.from")} {props.date.from} {t("gloabl.to")} {props.date.to}
        </CardDescription>
        <div className="flex py-2 w-full  justify-between text-xl">
          <p>{t("gloabl.sumVisits")}</p> <p>{props.sum}</p>
        </div>
      </CardHeader>
      <CardContent className="p-2">
        <ChartContainer config={chartConfig}>
          <BarChart accessibilityLayer data={props.data}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              //   tickLine={}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => value.slice(5, 10)}
            />
            <YAxis
              dataKey="numVisit"
              //   tickLine={}
              tickMargin={10}
              axisLine={false}
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="dashed" />}
            />
            <Bar
              dataKey="numVisit"
              layout="vertical"
              fill="var(--color-numVisit)"
              radius={4}
            >
              <LabelList
                dataKey="numVisit"
                position="top"
                offset={8}
                className="fill-foreground"
                fontSize={12}
              />
            </Bar>
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
