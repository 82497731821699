export const localeEn = {
  gloabl: {
    add: "Add",
    edit: "Edit",
    name: "Name",
    arabicName: "Arabic name",
    englishName: "English name",
    itemName: "Item name",
    address: "Address",
    arabicAddress: "Arabic address",
    englishAddress: "English address",
    image: "Image ",
    anImage: "Image",
    logo: "Logo",
    menu: "Menu",
    category: "Category",
    option: "option",
    url: "url",
    cancel: "Cancel",
    save: "Save",
    delete: "Delete",
    upload: "Upload",
    price: "Price",
    price_offer: "Descount price",
    offers: "Offers",
    itemProperties: "Item properties",
    description_ar: "Arabic Description",
    description_en: "English Description",
    description: "Description",
    hidden: "Hidden",
    unavailable: "unavailable",
    popularItem: "Popular ",
    newItem: "New",
    option_Sets: "Options",
    options_of: "Options of",
    subOptions: "Sub Options",
    sub_option: "sub option ",
    list: "List",
    pluse: "pluse",
    change_price: "Change price",
    field_requird: "The field is requred",
    search_to_select: "Search to Select",
    LogOut: "LogOut",
    Login: "Login",
    Username: "Username",
    user: "User",
    users: "Users",
    Password: "Password",
    chooseColor: "Choose Color",
    colorsMenu: "Colors Menu",
    primary: "Primary color",
    onPrimary: "Font color on Primary",
    secondary: "Secondary color ",
    onSecondary: "Font color on Secondary",
    enable: "Enable color ",
    disable: "Disable color",
    background: "Background color",
    onBackground: "Font color",
    socialMedia: "Social media",
    branch: "Branch",
    mobile: "Mobile",
    phone: "Phone",
    branches: "Branches",
    qrCode: "QR code",
    restaurants: "Restaurants",
    restaurant: "Restaurant",
    Active: "Active",
    restaurantName: "Restaurant name",
    subOptionValue: "Value",
    yes: "Yes",
    no: "No",
    deleteMessage:
      "All items related to this item will be deleted. Do you want to delete? ",
    deleteMessage2: "Do you want to delete this item ? ",
    roulete: "Roulete",
    domin: "Domain",
    features: "Features",
    select: "Select",
    search: "Search",
    item: "Item",
    type: "Type",
    darkMode: "Dark Mode",
    defaultImage: "Default Image",
    sumVisits: "Total visits",
    apply: "Apply",
    sum: "Sum",
    from: "From",
    to: "To",
    view: "View",
    visit: "Visit",
    visits: "Visits",
    statistics: "Statistics",
    total: "Total",
    cities: "Cities",
    countries: "Countries",
    systems: "Systems",
    OS: "OS",
    city: "City",
    country: "Country",
    scan: "Scan",
    top: "Top",
    oSystem: "Operating System",
  },
  dashbord: {
    home: "Home",
    menus: "Menus",
    categories: "Categories",
    items: "Items",
    options: "Options",
    sort: "Sort",
    branches: "Branches",
    restaurants: "Restaurants",
    users: "Users",
    dashbordType: {
      0: "Menu",
      1: "Category",
      2: "Item",
      3: "option",
      4: "Sub Option",
      5: "Branch",
      6: "Restaurant",
      7: "User",
    },
  },
  userTypes: {
    0: " SuperAdmin",
    1: "Admin",
    2: "User",
  },
  errorMessage: {
    fieldRequired: " is requred",
    fillAll: "Please fill out all required fields",
    fieldCharsLength: `must be at least 2 characters`,
    startwith: "must start with",
    worngLogin: "Wrong username or password",
    serverError: "Server error",

    103: "this element is exist",
    noData: "No data found",
  },
  messages: {
    imageNotSelected:
      "If no image is selected, the image of the most recently added product will be used.",

    store: "Data has been stored successfully",
    update: "Data has been updated successfully",
    delete: "Data has been deleted successfully",
  },
};
