import { MainDialog } from "src/Components/Dialgos/MainDialog";
import React from "react";
import SubOptionForm from "../form/SubOptionForm";
import { useTranslation } from "react-i18next";
import { indexStore } from "src/store/indexStore";

function EditSubOptionModel(props) {
  const { t } = useTranslation();
  const { subOption, updateSubOptionFunction } = indexStore();
  const onSubmit = (values) => {
    const valuestoSubmit = {
      ...values,
      option_id: subOption.option_id,
      id: subOption.id,
      price: values.price ? values.price : 0,
      value: values.value ? values.value : null,
    };
    updateSubOptionFunction(valuestoSubmit);
  };

  return (
    <MainDialog
      trigger={props.trigger}
      title={`${t("gloabl.edit")} ${t("gloabl.sub_option")}   `}
    >
      <SubOptionForm
        onSubmit={onSubmit}
        editMode={true}
        datasource={subOption}
      />
    </MainDialog>
  );
}

export default EditSubOptionModel;
