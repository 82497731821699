import React from "react";
import { useTranslation } from "react-i18next";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

export const ColorPickerButton = (props) => {
  const { t } = useTranslation();
  const defaultColor = "#eee";
  const buttonStyle = {
    backgroundColor: props.color || defaultColor,
  };

  const colorPick = (
    <div
      className="w-8 h-8 rounded-sm  border-2 cursor-pointer	 "
      style={buttonStyle}
      disabled={props.disabled}
    ></div>
  );

  return (
    <Popover>
      <PopoverTrigger> {colorPick}</PopoverTrigger>
      <PopoverContent>{props.picker}</PopoverContent>
    </Popover>
  );
};
