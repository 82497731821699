import { showResponseMessage } from "../Components/utils/handellApiResponse";
import RestaurantsServices from "../api-services/restaurants-sevrices";

export const createRestautantSlice = (set) => ({
  restaurants: [],
  restaurant: {},
  restaurantLoading: false,
  restaurantsLoading: false,
  restaurantId: null,
  fetchResturantsFunction: async () => {
    set((state) => ({
      ...state,
      restaurantsLoading: true,
    }));
    try {
      const response = await RestaurantsServices.getRestaurants();
      set((state) => ({
        ...state,
        restaurants: response.data.data,
        restaurantsLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        restaurantsLoading: false,
      }));
    }
  },
  fetchResturantFunction: async (restaurantId) => {
    set((state) => ({
      ...state,
      restaurantLoading: true,
    }));
    try {
      const response = await RestaurantsServices.getRestaurant(restaurantId);
      set((state) => ({
        ...state,
        restaurant: response.data.data,
        restaurantLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        restaurantLoading: false,
      }));
    }
  },
  insertResturantsFunction: async (data) => {
    set((state) => ({
      ...state,
      restaurantsLoading: true,
    }));
    try {
      const response = await showResponseMessage(
        RestaurantsServices.storeRestaurant(data),
        "store"
      );
      set((state) => ({
        ...state,
        restaurants: [...state.restaurants, response.data.data],
        restaurantsLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        restaurantsLoading: false,
      }));
    }
  },
  deleteResturantsFunction: async (id) => {
    set((state) => ({
      ...state,
      restaurantsLoading: true,
    }));
    try {
      const response = await showResponseMessage(
        RestaurantsServices.DeleteRestaurant(id),
        "delete"
      );
      set((state) => ({
        ...state,
        restaurants: state.restaurants.filter((e) => e.id !== id),
        restaurantsLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        restaurantsLoading: false,
      }));
    }
  },
  updateResturantsFunction: async (data) => {
    set((state) => ({
      ...state,
      restaurantsLoading: true,
    }));
    try {
      const response = await showResponseMessage(
        RestaurantsServices.updateRestaurant(data),
        "update"
      );
      set((state) => ({
        ...state,
        restaurants: state.restaurants.map((e) => {
          if (e.id == data.id) e = response.data.data;
          return e;
        }),
        restaurantsLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        restaurantsLoading: false,
      }));
    }
  },
  setRestaurantId: (id) => {
    set((state) => ({
      ...state,
      restaurantId: id,
    }));
  },
  setRestaurant: (data) => {
    set((state) => ({
      ...state,
      restaurant: data,
    }));
  },
});
