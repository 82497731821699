import { showResponseMessage } from "../Components/utils/handellApiResponse";
import usersServices from "../api-services/users-services";

export const createUserSlice = (set) => ({
  users: [],
  usersLoading: false,
  user: {},
  userLoading: false,
  fetchUserFunction: async () => {
    set((state) => ({
      ...state,
      userLoading: true,
    }));
    try {
      const response = await usersServices.getUser();
      set((state) => ({
        ...state,
        user: response.data.data,
        userLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        userLoading: false,
      }));
    }
  },
  fetchUsersFunction: async () => {
    set((state) => ({
      ...state,
      usersLoading: true,
    }));
    try {
      const response = await usersServices.getUsersTenant();
      set((state) => ({
        ...state,
        users: response.data.data,
        usersLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        usersLoading: false,
      }));
    }
  },
  storeUserTenantFunction: async (payload) => {
    set((state) => ({
      ...state,
      usersLoading: true,
    }));
    try {
      const response = await showResponseMessage(
        usersServices.storeUserTenant(payload),
        "store"
      );

      set((state) => ({
        ...state,
        users: [...state.users, response.data.data.user],
        usersLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        usersLoading: false,
      }));
    }
  },
  updateUserTenantFunction: async (payload) => {
    set((state) => ({
      ...state,
      usersLoading: true,
    }));
    try {
      const response = await showResponseMessage(
        usersServices.updateUserTenant(payload),
        "update"
      );

      set((state) => ({
        ...state,
        users: state.users.map((e) => {
          if (e.id === payload.id) {
            return response.data.data;
          }
          return e;
        }),
        usersLoading: false,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        usersLoading: false,
      }));
    }
  },
  deleteUserTenantFunction: async (id) => {
    set((state) => ({
      ...state,
      usersLoading: true,
    }));
    try {
      const response = await showResponseMessage(
        usersServices.DeleteUserTenant(id),
        "delete"
      );
      set((state) => ({
        ...state,
        users: state.users.filter((e) => e.id !== id),
        usersLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        usersLoading: false,
      }));
    }
  },
  setUser: (payload) => set((state) => ({ ...state, user: payload })),
});
