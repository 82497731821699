import React from "react";
import { SketchPicker } from "react-color";
import { ColorPickerButton } from "./ColorPickerButton";

function ColorPicker(props) {
  const defaultColor = "#eee";
  return (
    <ColorPickerButton
      {...props}
      picker={
        <SketchPicker
          color={props.color || defaultColor}
          onChangeComplete={(e) => props.onChange(e.hex)}
        />
      }
    />
  );
}

export default ColorPicker;
