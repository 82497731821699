import React from "react";
import QRCode from "qrcode.react";
import { RiDownload2Line } from "react-icons/ri";
import { downloadPDF } from "../utils/DownloadQR";
import Visibility from "../utils/Visibility";
// import { FaDownload } from "react-icons/fa6";

const QRCodeGenerator = (props) => {
  return (
    <div className="flex gap-5 flex-col">
      <QRCode
        className="w-44 aspect-square"
        id="qrcode-canvas"
        value={props.value}
      />
      <Visibility visible={props.visibleIcon}>
        <RiDownload2Line
          className="w-7 h-7 cursor-pointer"
          onClick={downloadPDF}
        />
      </Visibility>
    </div>
  );
};

export default QRCodeGenerator;
