import React from "react";
import { indexStore } from "../../store/indexStore";
import { IMG_URl } from "../../common/img_url";
import { CiEdit } from "react-icons/ci";
import { MdOutlineDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { AlertDialogs } from "../Dialgos/Alter-Daylogs";
import { CardMain } from "./Card";
import EditRestaurantModel from "../pages/restaurant/EditRestaurantModel";
import { Skeleton } from "../ui/skeleton";

function RestaurantCard(props) {
  const { t } = useTranslation();
  const { nameLang, deleteResturantsFunction, setRestaurant, description } =
    indexStore();
  const editRestaurant = (element) => {
    // props.setOpen(true);
    setRestaurant(element);
  };
  const handelDeleteRestaurant = (id) => {
    deleteResturantsFunction(id);
  };

  return (
    <CardMain
      key={props.element.id}
      title={props.element[nameLang]}
      description={props.element[description]}
      footer={[
        <EditRestaurantModel
          trigger={
            <CiEdit
              className="text-xl cursor-pointer"
              onClick={() => editRestaurant(props.element)}
            />
          }
        />,
        <AlertDialogs
          trigger={
            <MdOutlineDelete className="text-xl cursor-pointer text-red-600 " />
          }
          variant="icon"
          onClickOk={() => handelDeleteRestaurant(props.element.id)}
          description={<spn>{t("gloabl.deleteMessage")}</spn>}
          title={`${t("gloabl.delete")} ${t(`gloabl.restaurant`)}`}
        />,
      ]}
    >
      <img
        src={`${IMG_URl}/${props.element.logo}`}
        alt=""
        className="w-full h-28 object-contain aspect-square"
      />
    </CardMain>
  );
}

export default RestaurantCard;

export const RestaurantCardLoaing = () => (
  <CardMain
    title={<Skeleton className="w-24 h-5" />}
    description={<Skeleton className="w-32 h-5" />}
    footer={[
      <Skeleton className="w-5 h-5" />,
      <Skeleton className="w-5 h-5" />,
    ]}
  >
    <Skeleton className="w-full h-28 object-contain aspect-square" />
  </CardMain>
);
