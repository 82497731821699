import React, { useEffect } from "react";
import { BranchSliderLinks } from "../../common/Links/branchSliderLinks";
import { Route, Routes, useParams } from "react-router-dom";
import Menus from "../../pages/menus";
import Categories from "../../pages/categories";
import Items from "../../pages/items";
import Options from "../../pages/options";
import Sort from "../../pages/sort";
import BranchHomePage from "../pages/HomePages/BranchHomePage";
import { indexStore } from "../../store/indexStore";
import Sidebar from "../SiderLayout/SiderBar";
import MobileNav from "../SiderLayout/MobileNav";

function BranchLayout() {
  const branchLinks = BranchSliderLinks();
  const { fetchCategoriesAction, fetchMenusAction } = indexStore();
  const { branchId } = useParams();
  useEffect(() => {
    fetchMenusAction(branchId);
    fetchCategoriesAction(branchId);
  }, []);
  return (
    <div className="flex flex-col  min-h-screen  w-full  md:grid grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]">
      <div className="bg-backgroundConant">
        <Sidebar siderLinks={branchLinks} />
        <MobileNav siderLinks={branchLinks} />
      </div>

      <div className="p-5 bg-backgroundConant  flex-1 ">
        <Routes>
          <Route path={`/`} element={<BranchHomePage />} />
          <Route path={`/menus`} element={<Menus />} />
          <Route path={`/categories`} element={<Categories />} />
          <Route path={`/items`} element={<Items />} />
          <Route path={`/options`} element={<Options />} />
          <Route path={`/sort`} element={<Sort />} />
        </Routes>
      </div>
    </div>
  );
}

export default BranchLayout;
