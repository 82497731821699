import { createBranchSlice } from "./createBranchSlice";
import { createCategoriesSlice } from "./createCategoriesSlice";
export const createAppSlice = (set) => ({
  lang: "",
  nameLang: "",
  description: "",
  changeLang: (lang) => {
    localStorage.setItem("Lang", lang);
    set((state) => ({
      ...state,
      lang: lang,
      nameLang: lang === "ar" ? "name_ar" : "name_en",
      description: lang === "ar" ? "description_ar" : "description_en",
    }));
  },

  resetAll: () => {
    createBranchSlice(set).reset();
    createCategoriesSlice(set).reset();
  },
});
