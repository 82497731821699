import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import Iterator from "../utils/Iterator";
import { langs } from "../utils/langs";
import { useTranslation } from "react-i18next";
import { indexStore } from "src/store/indexStore";

export function MyTabs(props) {
  const { t } = useTranslation();
  const { lang } = indexStore();
  return (
    <Tabs
      dir={lang === "ar" ? "rtl" : "ltr"}
      defaultValue={"en"}
      className={props.className}
    >
      <TabsList className="grid w-full grid-cols-4 bg-transparent ">
        <Iterator
          data={langs}
          render={(e, i) => (
            <TabsTrigger value={e} key={i}>
              {e === "ar" ? "العربية" : "English"}
            </TabsTrigger>
          )}
        />
      </TabsList>
      <Iterator
        data={langs}
        render={(e, i) => (
          <TabsContent key={i} value={e}>
            {props.tabRender(e)}
          </TabsContent>
        )}
      />
    </Tabs>
  );
}
