import React from "react";
import { useTranslation } from "react-i18next";
import { indexStore } from "../../../store/indexStore";
import { getUniqueCategories } from "../../utils/UtilsFunctions";
import Iterator from "src/Components/utils/Iterator";
import { IoIosAdd } from "react-icons/io";
import { Button } from "src/Components/ui/button";
import AddItemsModel from "./AddItemsModel";
import {
  ProductCard,
  ProductCardLoading,
} from "src/Components/Cards/ProductCard";
import { Combobox } from "src/Components/selectbox/ComboBox";
import Visibility from "src/Components/utils/Visibility";
function Items(props) {
  const {
    fetchItemsFunction,
    items,
    itemsLoading,
    nameLang,
    categories,
    setCategoryItemsOptionId,
    categoryItemsOptionId,
    menus,
  } = indexStore();
  const { t } = useTranslation();

  const onSelect = (value, option) => {
    fetchItemsFunction(value);
    setCategoryItemsOptionId(value);
  };

  const uniqueCategories = getUniqueCategories(categories, menus, nameLang);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold">{t("dashbord.items")}</h2>

        <AddItemsModel
          trigger={
            <Button>
              <IoIosAdd />
              <span className="ml-2 hidden md:block">
                {t("gloabl.add")} {t(`gloabl.item`)}
              </span>
            </Button>
          }
        />
      </div>
      <Combobox
        data={uniqueCategories}
        onChange={onSelect}
        value={categoryItemsOptionId}
        placeholder={t("gloabl.select") + " " + t("gloabl.category")}
        lable={"displayName"}
      />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
        <Visibility visible={itemsLoading}>
          <Iterator
            data={[1, 2, 3]}
            render={(row) => <ProductCardLoading key={row} />}
          />
        </Visibility>
        <Visibility visible={!itemsLoading}>
          <Iterator data={items} render={(row) => <ProductCard {...row} />} />
        </Visibility>
      </div>
    </div>
  );
}

export default Items;
