import { axiosInstance } from "../axios-instance";

const user = "user";
const userTenant = "userTenant";
const usersServices = {
  getUsersTenant: () => axiosInstance.get(`${userTenant}`),
  getUser: (userId) => axiosInstance.get(`${user}/show/${userId}`),
  storeUserTenant: (payload) =>
    axiosInstance.postForm(`registerTenant`, payload),
  updateUserTenant: (payload) =>
    axiosInstance.postForm(`${userTenant}/update/${payload.id}`, payload),
  DeleteUserTenant: (id) => axiosInstance.delete(`${userTenant}/destroy/${id}`),
};
export default usersServices;
