import React, { useEffect } from "react";
import CategoryForm from "./form/categoryForm";
import { dashbordType } from "../../../enums/dashbordType";
import { useNavigate, useParams } from "react-router-dom";
import { indexStore } from "../../../store/indexStore";
import { MainDialog } from "src/Components/Dialgos/MainDialog";
import { useTranslation } from "react-i18next";

const EditCategoryModel = (props) => {
  const { t } = useTranslation();
  const { updateCategoryAction, category, fetchCategoryAction } = indexStore();
  const navigate = useNavigate();
  const { id, branchId } = useParams();

  // useEffect(() => {
  //   fetchCategoryAction(id);
  // }, []);

  const handleSubmitAddCategory = (values) => {
    const payload = {
      ...values,
      id: category.id,
    };
    updateCategoryAction(payload);
  };

  return (
    <MainDialog
      // open={props.open}
      trigger={props.trigger}
      title={`${t("gloabl.add")} ${t(`gloabl.category`)}`}
    >
      <CategoryForm
        type={dashbordType.Categories}
        onSubmit={handleSubmitAddCategory}
        editMode={true}
        datasource={category}
      />
    </MainDialog>
  );
};

export default EditCategoryModel;
