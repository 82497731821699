import * as React from "react";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "src/Components/ui/select";
import Iterator from "../utils/Iterator";
import { indexStore } from "src/store/indexStore";

export function SelectBox(props) {
  const { lang } = indexStore();

  return (
    <Select
      dir={lang === "ar" ? "rtl" : "ltr"}
      onValueChange={(value, option) => props.onValueChange(value, option)}
      value={props.value}
    >
      <SelectTrigger className={props.width || "w-72"}>
        <SelectValue placeholder={props.placeholder} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>{props.labelSelect}</SelectLabel>
          <Iterator
            data={props.data}
            render={(item) => (
              <SelectItem value={item[props.valueKey]}>
                {item[props.label]}
              </SelectItem>
            )}
          />
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
