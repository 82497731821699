export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1); // getMonth() returns 0-indexed month
  const day = String(date.getDate());

  return `${year}-${month}-${day}`;
};

export const getFirstDayOfMonth = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), 1);
};

export const getToday = () => {
  return new Date();
};
