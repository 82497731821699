import * as React from "react";
import { useTheme } from "next-themes";

import { Switch } from "../ui/switch";
import { useTranslation } from "react-i18next";

export function DarkModeToggle() {
  const { setTheme, theme } = useTheme();
  const { t } = useTranslation();
  return (
    <div className="w-full flex justify-start gap-2 px-2 py-4">
      <Switch
        className="relative inline-flex h-6 w-11 items-center rounded-full "
        checked={theme === "dark"}
        dir="ltr"
        onCheckedChange={(checked) => setTheme(checked ? "dark" : "light")}
        aria-label="Toggle dark mode"
      ></Switch>
      <span className="text-sm font-medium">{t("gloabl.darkMode")}</span>
    </div>
  );
}
