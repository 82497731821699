import React from "react";
import {
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../Components/ui/form";
function FormFieldItem(props) {
  return (
    <FormItem>
      <FormLabel required={props.required}>{props.label}</FormLabel>
      <FormControl>{props.children}</FormControl>
      <FormDescription>{props.description}</FormDescription>
      <FormMessage />
    </FormItem>
  );
}

export default FormFieldItem;
