import { FaCodeBranch } from "react-icons/fa6";
import { Home, Users } from "lucide-react";
import { MdOutlineRestaurant } from "react-icons/md";

export const ManiSliderLinks = () => {
  return [
    {
      icon: <MdOutlineRestaurant className="h-4 w-4" />,
      title: "dashbord.restaurants",
      link: "/restaurants",
    },
    {
      icon: <Users className="h-4 w-4" />,
      title: "dashbord.users",
      link: "/users",
    },
    {
      icon: <Home className="h-4 w-4" />,
      title: "dashbord.home",
      link: `/`,
    },
    {
      icon: <FaCodeBranch className="h-4 w-4" />,
      title: "dashbord.branches",
      link: `/branches`,
    },
  ];
};
