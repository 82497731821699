import { showResponseMessage } from "../Components/utils/handellApiResponse";
import itemsServices from "../api-services/items-services";
import optionsServices from "../api-services/options-services";

export const createOptionsSlice = (set) => ({
  option: {},
  optionLoading: false,
  subOptions: [],
  subOptionsLoading: false,
  ItemOptions: [],
  ItemOptionsLoading: false,
  itemIdOptions: "",
  itemsOptions: [],
  subOption: {},

  fetchOptionsOfItemsByIdFunction: async (itemId) => {
    set((state) => ({
      ...state,
      ItemOptionsLoading: true,
    }));
    try {
      const res = await optionsServices.getOptionsOfItemByID(itemId);

      set((state) => ({
        ...state,
        ItemOptions: res.data.data.option,
        // subOptions: res.data.data.option.subOption,
        ItemOptionsLoading: false,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        ItemOptionsLoading: false,
      }));
    }
  },

  fetchOptionbyIdFunction: async (id) => {
    set((state) => ({
      ...state,
      ItemOptionsLoading: true,
    }));
    try {
      const res = await optionsServices.getOption(id);

      set((state) => ({
        ...state,
        option: res.data.data,
        subOptions: res.data.data.subOption,
        ItemOptionsLoading: false,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        ItemOptionsLoading: false,
      }));
    }
  },

  storeOptionFunction: async (dataOptions) => {
    set((state) => ({
      ...state,
      ItemsOptionsLoading: true,
      optionLoading: true,
    }));
    try {
      const res = await showResponseMessage(
        optionsServices.storeOption(dataOptions),
        "store"
      );
      const option = { ...res.data.data, subOption: [] };

      set((state) => ({
        ...state,
        ItemOptions: [...state.ItemOptions, option],
        ItemsOptionsLoading: false,
        optionLoading: false,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        ItemsOptionsLoading: false,
        optionLoading: false,
      }));
    }
  },
  updateOptionFunction: async (dataOptions) => {
    set((state) => ({
      ...state,
      ItemsOptionsLoading: true,
      optionLoading: true,
    }));
    try {
      const res = await showResponseMessage(
        optionsServices.updateOption(dataOptions),
        "update"
      );

      set((state) => ({
        ...state,
        ItemOptions: state.ItemOptions.map((item) =>
          item.id === res.data.data.id
            ? { ...res.data.data, subOption: item.subOption }
            : item
        ),
        ItemsOptionsLoading: false,
        optionLoading: false,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        ItemsOptionsLoading: false,
        optionLoading: false,
      }));
    }
  },

  deleteOptionFunction: async (id) => {
    set((state) => ({
      ...state,
      ItemsOptionsLoading: true,
    }));
    try {
      const res = await showResponseMessage(
        optionsServices.DeleteOption(id),
        "delete"
      );
      set((state) => ({
        ...state,
        ItemOptions: state.ItemOptions.filter((item) => item.id !== id),
        ItemsOptionsLoading: false,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        ItemsOptionsLoading: false,
      }));
    }
  },
  setItemIdOptions: (id) => {
    set({ itemIdOptions: id });
  },
  /////////////// //////////////////////////////////////////////
  /////////////// //////////////////////////////////////////////
  /////////////// //////////////////////////////////////////////
  /////////////// //////////////////////////////////////////////
  /////////////// subOptions//////////////////////////////////////////////
  /////////////// //////////////////////////////////////////////
  /////////////// //////////////////////////////////////////////
  /////////////// //////////////////////////////////////////////
  fetchSubOptionsFunction: async (optionId) => {
    set((state) => ({
      ...state,
      subOptionsLoading: true,
    }));
    try {
      const res = await optionsServices.getSubOptions(optionId);
      set((state) => ({
        ...state,
        subOptions: res.data.data,
        subOptionsLoading: false,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        subOptionsLoading: false,
      }));
    }
  },
  storeSubOptionFunction: async (payload) => {
    set((state) => ({
      ...state,
      subOptionsLoading: true,
    }));
    try {
      const res = await showResponseMessage(
        optionsServices.storeSubOption(payload),
        "store"
      );

      set((state) => ({
        ...state,
        subOptions: [...state.subOptions, res.data.data],
        ItemOptions: state.ItemOptions.map((item) => {
          if (item.id === payload.option_id) {
            const newSubOptions = [...item.subOption, res.data.data];
            console.log({ ...item, subOption: newSubOptions });
            return { ...item, subOption: newSubOptions };
          }
          return item;
        }),
        subOptionsLoading: false,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        subOptionsLoading: false,
      }));
    }
  },

  updateSubOptionFunction: async (payload) => {
    set((state) => ({
      ...state,
      subOptionsLoading: true,
    }));
    try {
      const res = await showResponseMessage(
        optionsServices.updateSubOption(payload),
        "update"
      );
      set((state) => ({
        ...state,
        ItemOptions: state.ItemOptions.map((item) => {
          if (item.id === payload.option_id) {
            const updatedSubOptions = item.subOption.map((sub) => {
              if (sub.id === payload.id) {
                return res.data.data;
              }
              return sub;
            });
            return { ...item, subOption: updatedSubOptions };
          }
          return item;
        }),

        subOptions: state.subOptions.map((item) =>
          item.id == payload.id ? res.data.data : item
        ),
        subOptionsLoading: false,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        subOptionsLoading: false,
      }));
    }
  },
  deleteSubOptionFunction: async (payload) => {
    set((state) => ({
      ...state,
      subOptionsLoading: true,
    }));
    try {
      const res = await showResponseMessage(
        optionsServices.DeleteSubOption(payload.id),
        "delete"
      );
      set((state) => ({
        ...state,
        ItemOptions: state.ItemOptions.map((item) => {
          if (item.id === payload.option_id) {
            item.subOption = item.subOption.filter(
              (sub) => sub.id !== payload.id
            );
          }
          return item;
        }),
        subOptions: state.subOptions.filter((item) => item.id !== payload.id),
        subOptionsLoading: false,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        subOptionsLoading: false,
      }));
    }
  },
  /////////////// //////////////////////////////////////////////
  /////////////// //////////////////////////////////////////////
  /////////////// //////////////////////////////////////////////
  /////////////// //////////////////////////////////////////////
  /////////////// get all items//////////////////////////////////////////////
  /////////////// //////////////////////////////////////////////
  /////////////// //////////////////////////////////////////////
  /////////////// //////////////////////////////////////////////

  fetchItemsOfOptionsFunction: async (branchId) => {
    set((state) => ({
      ...state,
      itemsLoading: true,
    }));
    try {
      const res = await itemsServices.getItems(branchId);
      set((state) => ({
        ...state,
        itemsOptions: res.data.data,
        itemsLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        itemsLoading: false,
      }));
    }
  },
  //////////////////////// /////////////////////////////////////////
  /////////////////// rest options //////////////////////////////////
  //////////////////////// /////////////////////////////////////////
  resetOptions: () => {
    set((state) => ({
      ...state,
      ItemOptions: [],
      subOptions: [],
      itemIdOptions: "",
    }));
  },

  /////////////// //////////////////////////////////////////////
  /////////////// //////////////////////////////////////////////
  setOption: (value) => set((state) => ({ ...state, option: value })),
  setSubOption: (value) => set((state) => ({ ...state, subOption: value })),
});
