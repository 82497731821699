import StatisticsIndex from "src/Components/Statistics/StatisticsIndex";
import React from "react";
import Orders from "src/Components/OrdersBranch/Orders";

const BranchComponent = () => {
  return (
    <div className="flex flex-col gap-5">
      <StatisticsIndex />
      {/* <Orders /> */}
    </div>
  );
};

export default BranchComponent;
