import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { indexStore } from "../../../../store/indexStore";
import { Button } from "../../../ui/button";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Form, FormField } from "src/Components/ui/form";
import MenuTabs from "../../menus/MenuTabs";
import FormFieldItem from "../../../../common/form/FormFieldItem";
import { DialogClose } from "@radix-ui/react-dialog";
import { ComboboxForm } from "src/Components/selectbox/ComboBoxForm";

function CategoryForm(props) {
  const { categoryLoading, menus, nameLang } = indexStore();
  const { t } = useTranslation();

  const formSchema = z.object({
    name_ar: z
      .string({
        required_error: `${t("gloabl.name")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.name")} ${t("errorMessage.fieldCharsLength")}.`,
      }),
    name_en: z
      .string({
        required_error: `${t("gloabl.name")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.name")} ${t("errorMessage.fieldCharsLength")}.`,
      }),

    menu_id: z.any({
      required_error: `${t("gloabl.menu")} ${t("errorMessage.fieldRequired")}.`,
    }),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),

    defaultValues: {
      ...props.datasource,
    },
  });
  const dialogCloseRef = useRef();
  const onSubmit = (values) => {
    const submitValues = {
      ...values,
    };
    // console.log(submitValues);

    props.onSubmit(submitValues);
    if (dialogCloseRef.current) {
      dialogCloseRef.current.click();
    }
  };
  const onErorr = (errors) => {
    toast.error(t("errorMessage.fillAll"));
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, onErorr)}
        className="space-y-8"
      >
        <MenuTabs form={form} className="bg-transparent " />

        <FormField
          control={form.control}
          name="menu_id"
          render={({ field }) => {
            return (
              <FormFieldItem required label={t("gloabl.menu")} form={form}>
                <ComboboxForm
                  data={menus}
                  {...field}
                  placeholder={t("gloabl.select") + " " + t("gloabl.menu")}
                  lable={nameLang}
                />
              </FormFieldItem>
            );
          }}
        />
        <div className="flex items-center gap-3 ">
          <DialogClose asChild>
            <Button type="button" variant="outline">
              {t("gloabl.cancel")}
            </Button>
          </DialogClose>
          <DialogClose ref={dialogCloseRef} />
          <Button type="submit">{t("gloabl.save")}</Button>
        </div>
      </form>
    </Form>
  );
}

export default CategoryForm;
