import { showResponseMessage } from "../Components/utils/handellApiResponse";
import PropertiesServices from "../api-services/properties-services";

export const createPropertiesSlice = (set) => ({
  style: {
    colors: {
      primary: "#000",
      onPrimary: "#fff",
      secondary: "#000",
      // onSecondary: "#fff",
      // enable: "#fff",
      disable: "#333",
      background: "#fcfcfc",
      onBackground: "#222",
    },
  },
  styleLoading: false,
  socialMedia: [],
  socialMediaElemnt: {},
  socialMediaLoading: false,

  changeColorsFunction: async (data) => {
    const updateDate = { ...data, ...data.colors };
    delete updateDate.colors;
    try {
      const res = await PropertiesServices.updateStyle(updateDate);
      set((state) => ({
        ...state,
        style: {
          id: data.id,
          restaurant_id: data.restaurant_id,
          colors: data.colors,
        },
      }));
    } catch {
      set((state) => ({
        ...state,
      }));
    }
  },

  storeColorsFunction: async (data) => {
    try {
      const res = await PropertiesServices.storeStyle(data);
      set((state) => ({
        ...state,
        style: {
          id: res.data.data.id,
          restaurant_id: data.restaurant_id,
          colors: data.colors,
        },
        styleLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
      }));
    }
  },

  fetchColorsFunction: async () => {
    set((state) => ({
      ...state,
      styleLoading: true,
    }));
    try {
      const res = await PropertiesServices.getStyle();

      const { id, restaurant_id, updated_at, created_at, ...colors } =
        res.data.data[0];
      set((state) => ({
        ...state,
        style: {
          id: id,
          restaurant_id: restaurant_id,
          colors: colors,
        },
        styleLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        styleLoading: false,
      }));
    }
  },

  fetchSocialMediaFunction: async () => {
    set((state) => ({
      ...state,
      socialMediaLoading: true,
    }));
    try {
      const res = await PropertiesServices.getSocailMedia();
      set((state) => ({
        ...state,
        socialMedia: res.data.data,
        socialMediaLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        socialMediaLoading: false,
      }));
    }
  },

  updateSocialMediaFunction: async (item) => {
    set((state) => ({
      ...state,
      socialMediaLoading: true,
    }));
    try {
      const res = await showResponseMessage(
        PropertiesServices.updateSocailMedia(item),
        "update"
      );
      set((state) => ({
        ...state,
        socialMedia: state.socialMedia.map((e) => {
          if (e.id === item.id) e = res.data.data;
          return e;
        }),
        socialMediaLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        socialMediaLoading: false,
      }));
    }
  },
  storeSocialMediaFunction: async (item) => {
    set((state) => ({
      ...state,
      socialMediaLoading: true,
    }));
    try {
      const res = await showResponseMessage(
        PropertiesServices.storeSocailMedia(item),
        "store"
      );
      set((state) => ({
        ...state,
        socialMedia: [...state.socialMedia, res.data.data],
        socialMediaLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        socialMediaLoading: false,
      }));
    }
  },

  deleteSocialMediaFunction: async (id) => {
    set((state) => ({
      ...state,
      socialMediaLoading: true,
    }));
    const res = await showResponseMessage(
      PropertiesServices.deleteSocailMedia(id),
      "delete"
    );
    try {
      set((state) => ({
        ...state,
        socialMedia: state.socialMedia.filter((e) => e.id !== id),
        socialMediaLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        socialMediaLoading: false,
      }));
    }
  },
  setSocialMediaElement: (element) => {
    set((state) => ({
      ...state,
      socialMediaElemnt: element,
    }));
  },
});
