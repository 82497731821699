import { MainDialog } from "src/Components/Dialgos/MainDialog";
import React from "react";
import { useTranslation } from "react-i18next";
import SubOptionForm from "../form/SubOptionForm";
import { indexStore } from "src/store/indexStore";

function AddSubOptionModel(props) {
  const { t } = useTranslation();
  const { storeSubOptionFunction } = indexStore();

  const onSubmit = (values) => {
    // const num = Number(id);
    const valuestoSubmit = {
      ...values,
      option_id: props.option_id,
      price: values.price ? values.price : 0,
      value: values.value ? values.value : null,
    };
    console.log(valuestoSubmit);

    storeSubOptionFunction(valuestoSubmit);
  };

  return (
    <MainDialog
      trigger={props.trigger}
      title={`${t("gloabl.add")} ${t("gloabl.sub_option")}   `}
    >
      <SubOptionForm onSubmit={onSubmit} editMode={false} />
    </MainDialog>
  );
}

export default AddSubOptionModel;
