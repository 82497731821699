import React from "react";
import OptionsForm from "./form/optionsForm";
import { indexStore } from "../../../store/indexStore";
import { MainDialog } from "src/Components/Dialgos/MainDialog";
import { useTranslation } from "react-i18next";

function AddOptionModel(props) {
  const { t } = useTranslation();
  const { nameLang, storeOptionFunction } = indexStore();

  const handleAddOptions = (values) => {
    storeOptionFunction(values);
  };
  return (
    <MainDialog
      trigger={props.trigger}
      title={`${t("gloabl.add")} ${t("gloabl.option")}`}
    >
      <OptionsForm editMode={false} onSubmit={handleAddOptions} />
    </MainDialog>
  );
}

export default AddOptionModel;
