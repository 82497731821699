import * as React from "react";
import { useController } from "react-hook-form";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "src/Components/ui/select";
import Iterator from "../utils/Iterator";

export function SelectBoxForm({ name, control, ...props }) {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    // defaultValue: , // Set an empty array as the default value
  });

  return (
    <Select
      // defaultValue={}
      value={Number(field.value)}
      onValueChange={(value) => field.onChange(value)}
    >
      <SelectTrigger className="w-72">
        <SelectValue placeholder={props.placeholder} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>{props.labelSelect}</SelectLabel>
          <Iterator
            data={props.data}
            render={(item) => (
              <SelectItem value={item.id}>{item[props.label]}</SelectItem>
            )}
          />
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
