import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import AddSocailMediaModel from "./AddSocailMediaModel";
import { IoIosAdd } from "react-icons/io";
import Iterator from "../utils/Iterator";
import { indexStore } from "src/store/indexStore";
import { socialMediaTypes } from "src/enums/socialMediaType";
import EditSocialMediaModel from "./EditSocialMediaModel";
import { CiEdit } from "react-icons/ci";
import { AlertDialogs } from "../Dialgos/Alter-Daylogs";
import { MdOutlineDelete } from "react-icons/md";
import { Skeleton } from "../ui/skeleton";
import Visibility from "../utils/Visibility";

function SocialMedia(props) {
  const { t } = useTranslation();
  const {
    socialMedia,
    setSocialMediaElement,
    deleteSocialMediaFunction,
    socialMediaLoading,
  } = indexStore();
  return (
    <div className="flex flex-col gap-5">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-bold">{t("gloabl.socialMedia")}</h2>
        <AddSocailMediaModel
          trigger={
            <Button className="">
              <IoIosAdd />
              <span className="ml-2 hidden md:block">
                {t("gloabl.add")} {t(`gloabl.socialMedia`)}
              </span>
            </Button>
          }
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
        <Visibility visible={socialMediaLoading}>
          <Iterator data={[1, 2]} render={() => <SocialMediaCardLoading />} />
        </Visibility>
        <Visibility visible={!socialMediaLoading}>
          <Iterator
            data={socialMedia}
            render={(element) => (
              <SocialMeidaCard
                t={t}
                element={element}
                key={element.id}
                setSocialMediaElement={setSocialMediaElement}
                deleteSocialMediaFunction={deleteSocialMediaFunction}
              />
            )}
          />
        </Visibility>
      </div>
    </div>
  );
}

export default SocialMedia;
const SocialMeidaCard = ({
  element,
  t,
  setSocialMediaElement,
  deleteSocialMediaFunction,
}) => {
  const icon = socialMediaTypes.filter((e) => e.id == element.type)[0]?.label;
  console.log();

  const editRow = (element) => {
    setSocialMediaElement(element);
  };
  const onDelete = (element) => {
    deleteSocialMediaFunction(element.id);
  };
  return (
    <div className="w-full flex justify-between items-center gap-4 border p-2 ">
      <div className=" flex gap-2 items-center">
        <span className="text-2xl">{icon}</span>

        <div>
          <div className="flex gap-1">
            <p className="font-semibold">{t("gloabl.name")} :</p>
            <p>{element.name}</p>
          </div>
          <div className="flex gap-1">
            <p className="font-semibold uppercase">{t("gloabl.url")}:</p>
            <a
              className="cursor-pointer w-40 overflow-hidden md:w-24 lg:w-48 text-ellipsis whitespace-nowrap "
              href={element.value}
            >
              {element.value}
            </a>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center flex-shrink-0">
        <EditSocialMediaModel
          trigger={
            <CiEdit
              className="text-xl cursor-pointer"
              onClick={() => editRow(element)}
            />
          }
        />

        <AlertDialogs
          trigger={
            <MdOutlineDelete className="text-xl cursor-pointer text-red-600 " />
          }
          variant="icon"
          onClickOk={() => onDelete(element)}
          description={<spn>{t("gloabl.deleteMessage")}</spn>}
          title={`${t("gloabl.delete")} ${t(`gloabl.socialMedia`)}?`}
        />
      </div>
    </div>
  );
};

const SocialMediaCardLoading = () => {
  return (
    <div className="w-full flex justify-between items-center gap-4 border p-2 ">
      <Skeleton className="w-5 h-5" />

      <div className="flex w-full flex-col gap-1">
        <Skeleton className="w-1/2 h-6" />
        <Skeleton className="w-1/2 h-6" />
      </div>

      <div className="flex justify-between gap-1 items-center flex-shrink-0">
        <Skeleton className="w-5 h-5" />
        <Skeleton className="w-5 h-5" />
      </div>
    </div>
  );
};
