import { axiosInstance } from "../axios-instance";

const option = "option";

const optionsServices = {
  getOptions: (branchId) => axiosInstance.get(`${option}`),
  getOption: (productId) => axiosInstance.get(`${option}/show/${productId}`),
  storeOption: (payload) => axiosInstance.postForm(`${option}/store`, payload),
  updateOption: (payload) =>
    axiosInstance.postForm(`${option}/update/${payload.id}`, payload),
  DeleteOption: (id) => axiosInstance.delete(`${option}/delete/${id}`),

  getOptionsItemsByCategoryID: (categoryId) =>
    axiosInstance.get(`product/getProductWithOption/${categoryId}`),
  getOptionsOfItemByID: (itemId) =>
    axiosInstance.get(`product/getProductWithOptionById/${itemId}`),

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////subOption//////////////////////////////////////////

  getSubOptions: (optionId) => axiosInstance.get(`subOption/${optionId}`),
  storeSubOption: (payload) =>
    axiosInstance.postForm(`subOption/store`, payload),
  updateSubOption: (payload) =>
    axiosInstance.post(`subOption/update/${payload.id}`, payload),
  DeleteSubOption: (id) => axiosInstance.delete(`subOption/delete/${id}`),
};

export default optionsServices;
