import React from "react";
import { indexStore } from "../../../store/indexStore";
import ResturantForm from "./form/resturantForm";
import { MainDialog } from "src/Components/Dialgos/MainDialog";
import { useTranslation } from "react-i18next";

function EditRestaurantPage(props) {
  const { t } = useTranslation();
  const { restaurant, updateResturantsFunction } = indexStore();

  const handleSubmitEditResturant = (values) => {
    const t = typeof values.logo;
    if (t === "string") {
      delete values.logo;
    }
    const defaultImage = typeof values.default_image;
    if (defaultImage === "string") {
      delete values.default_image;
    }

    const p = {
      ...values,
      id: restaurant.id,
    };

    updateResturantsFunction(p);
  };
  // const changeOpen = (e) => {
  //   console.log(e);
  //   props.onOpenChange(e);
  // };
  return (
    <MainDialog
      // open={props.open}
      trigger={props.trigger}
      title={`${t("gloabl.edit")} ${t(`gloabl.restaurant`)}`}
    >
      <ResturantForm
        onSubmit={handleSubmitEditResturant}
        editMode={true}
        datasource={restaurant}
        // changeOpen={(e) => changeOpen(e)}
      />
    </MainDialog>
  );
}

export default EditRestaurantPage;
