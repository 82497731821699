import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../ui/button";
import { IMG_URl } from "../../../../common/img_url";
import { indexStore } from "../../../../store/indexStore";
import ImageSelected from "../../../Uploaders/ImageSelected";
import { Form, FormField } from "src/Components/ui/form";
import { toast } from "react-toastify";
import FormFieldItem from "../../../../common/form/FormFieldItem";
import { DialogClose } from "src/Components/ui/dialog";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import MenuTabs from "../MenuTabs";

function MenusForm(props) {
  const { menuLoading } = indexStore();
  const { t } = useTranslation();
  const [deleteImage, setDeleteImage] = useState(0);
  const [image, setImage] = useState("");

  const formSchema = z.object({
    name_ar: z
      .string({
        required_error: `${t("gloabl.name")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.name")} ${t("errorMessage.fieldCharsLength")}.`,
      }),
    name_en: z
      .string({
        required_error: `${t("gloabl.name")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.name")} ${t("errorMessage.fieldCharsLength")}.`,
      }),

    image: z
      .union([
        z.instanceof(File).refine((file) => file.size < 4 * 1024 * 1024, {
          message: "File size must be less than 4MB",
          required_error: `${t("gloabl.image")} ${t(
            "errorMessage.fieldRequired"
          )}.`,
        }),
        z.string({
          required_error: `${t("gloabl.image")} ${t(
            "errorMessage.fieldRequired"
          )}.`,
        }),
      ])
      .optional(),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),

    defaultValues: {
      ...props.datasource,
    },
  });
  const dialogCloseRef = useRef();
  const onSubmit = (values) => {
    const submitValues = {
      ...values,
      deleteImage: deleteImage,
    };
    // console.log(submitValues);

    props.onSubmit(submitValues);
    if (dialogCloseRef.current) {
      dialogCloseRef.current.click();
    }
  };
  const onErorr = (errors) => {
    toast.error(t("errorMessage.fillAll"));
  };
  useEffect(() => {
    if (props.editMode) {
      setImage(props.datasource.image ? IMG_URl + props.datasource.image : "");
    }
  }, [props.editMode]);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, onErorr)}
        className="space-y-8"
      >
        <MenuTabs form={form} className="bg-transparent " />

        <FormField
          control={form.control}
          name="image"
          render={({ field }) => {
            return (
              <FormFieldItem label={t("gloabl.image")} form={form}>
                <ImageSelected
                  form={form}
                  control={form.control}
                  name="image"
                  {...field}
                  editMode={props.editMode}
                  image={image}
                  setImage={setImage}
                  setDeleteImage={setDeleteImage}
                />
              </FormFieldItem>
            );
          }}
        />
        <div className="flex items-center gap-3 ">
          <DialogClose asChild>
            <Button type="button" variant="outline">
              {t("gloabl.cancel")}
            </Button>
          </DialogClose>
          <DialogClose ref={dialogCloseRef} />
          <Button type="submit">{t("gloabl.save")}</Button>
        </div>
      </form>
    </Form>
  );
}

export default MenusForm;
