import { MainDialog } from "src/Components/Dialgos/MainDialog";
import React from "react";
import UserForm from "./form/UserForm";
import { useTranslation } from "react-i18next";
import { indexStore } from "src/store/indexStore";

function AddUserModel(props) {
  const { t } = useTranslation();
  const { storeUserTenantFunction } = indexStore();

  const handleSubmitAddUser = (values) => {
    console.log(values);
    const submitValues = {
      ...values,
      userNameTenant: values.phone,
      isAdmin: 0,
    };

    storeUserTenantFunction(submitValues);
  };
  return (
    <MainDialog
      // open={props.open}
      trigger={props.trigger}
      title={`${t("gloabl.add")} ${t(`gloabl.user`)}`}
    >
      <UserForm onSubmit={handleSubmitAddUser} editMode={false} />
    </MainDialog>
  );
}

export default AddUserModel;
