import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "../ui/button";
import { AiOutlineLogout } from "react-icons/ai";
import { SheetClose } from "../ui/sheet";

export const LinkItem = ({ item, ...props }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const selectedKey =
    pathname === item.link ||
    (pathname.startsWith(item.link) && pathname[item.link.length] === "/");

  if (props.mobileNave) {
    return (
      <SheetClose asChild>
        {element({ item, selectedKey, t, ...props })}
      </SheetClose>
    );
  } else return element({ item, selectedKey, t, ...props });
};
const element = ({ item, selectedKey, t }) => (
  <Link
    to={item.link}
    className={`w-full flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary  ${
      selectedKey
        ? "bg-primary text-primary-foreground hover:text-primary-foreground"
        : ""
    }`}
  >
    {item.icon}
    {t(item.title)}
  </Link>
);

export const LogOutButton = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("resId");
    localStorage.removeItem("premssions");
    localStorage.removeItem("restName");
    navigate("/login");
  };
  return (
    <Button
      className="w-full flex justify-start gap-2"
      variant="contained"
      onClick={() => logOut()}
    >
      <AiOutlineLogout />

      <span className="ml-4">{t("gloabl.LogOut")}</span>
    </Button>
  );
};
