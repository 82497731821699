import React, { useEffect } from "react";

import { indexStore } from "src/store/indexStore";
import Iterator from "../../utils/Iterator";
import { useTranslation } from "react-i18next";
import { IoIosAdd } from "react-icons/io";

import { Button } from "../../ui/button";
import AddUserModel from "./AddUserModel";
import { UserCard, UserCardLoading } from "src/Components/Cards/UserCard";

import Visibility from "src/Components/utils/Visibility";

function Users() {
  const { fetchUsersFunction, users, fetchResturantsFunction, usersLoading } =
    indexStore();
  const { t } = useTranslation();

  useEffect(() => {
    fetchResturantsFunction();
    fetchUsersFunction();
  }, []);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold">{t("gloabl.users")}</h2>

        <AddUserModel
          trigger={
            <Button className="">
              <IoIosAdd />
              <span className="ml-2 hidden md:block">
                {t("gloabl.add")} {t(`gloabl.user`)}
              </span>
            </Button>
          }
        />
      </div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
        <Visibility visible={usersLoading}>
          <Iterator
            data={[1, 2]}
            render={(e, i) => <UserCardLoading key={i} />}
          />
        </Visibility>
        <Visibility visible={!usersLoading}>
          <Iterator data={users} render={(e, i) => <UserCard {...e} />} />
        </Visibility>
      </div>
    </div>
  );
}

export default Users;
