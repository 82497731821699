import { showResponseMessage } from "../Components/utils/handellApiResponse";
import BranchesServices from "../api-services/branches-services";

export const createBranchSlice = (set) => ({
  branches: [],
  branch: {},
  branchLoading: false,
  branchsLoading: false,
  featchBranchesFunction: async () => {
    set((state) => ({
      ...state,
      branchsLoading: true,
    }));
    try {
      const response = await BranchesServices.getBranchs();
      set((state) => ({
        ...state,
        branches: response.data.data,
        branchsLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        branchsLoading: false,
      }));
    }
  },
  featchBranchFunction: async (branchId) => {
    set((state) => ({
      ...state,
      branchLoading: true,
    }));
    try {
      const response = await BranchesServices.getBranch(branchId);
      set((state) => ({
        ...state,
        branch: response.data.data,
        branchLoading: false,
      }));
    } catch {
      set((state) => ({
        ...state,
        branchLoading: false,
      }));
    }
  },
  storeBranchesFunction: async (data) => {
    set((state) => ({
      ...state,
      branchsLoading: true,
    }));
    try {
      const response = await showResponseMessage(
        BranchesServices.storeBranch(data),
        "store"
      );
      set((state) => ({
        ...state,
        branches: [...state.branches, response.data.data],
        branchsLoading: false,
      }));
      return response;
    } catch (error) {
      set((state) => ({
        ...state,
        branchsLoading: false,
      }));
      return error;
    }
  },
  DeleteBranchesFunction: async (id) => {
    set((state) => ({
      ...state,
      branchsLoading: true,
    }));
    try {
      const response = await showResponseMessage(
        BranchesServices.DeleteBranch(id),
        "delete"
      );

      set((state) => ({
        ...state,
        branches: state.branches.filter((e) => e.id !== id),
        branchsLoading: false,
      }));
      return response;
    } catch (error) {
      set((state) => ({
        ...state,
        branchsLoading: false,
      }));
      return error;
    }
  },
  UpdateBranchesFunction: async (data) => {
    set((state) => ({
      ...state,
      branchsLoading: true,
    }));
    try {
      const response = await showResponseMessage(
        BranchesServices.updateBranch(data),
        "update"
      );

      set((state) => ({
        ...state,
        branches: state.branches.map((e) => {
          if (e.id === data.id) e = response.data.data;
          return e;
        }),
        branchsLoading: false,
      }));
      return response;
    } catch (error) {
      set((state) => ({
        ...state,
        branchsLoading: false,
      }));
      return error;
    }
  },
  setBranch: (data) => {
    set((state) => ({
      ...state,
      branch: data,
    }));
  },
});
