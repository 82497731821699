export const dashbordType = {
  menus: 0,
  Categories: 1,
  items: 2,
  options: 3,
  sort: 4,
};
export const getDashbordTypeName = (bondType) => {
  let typeName = "";
  switch (bondType) {
    case dashbordType.menus:
      typeName = "menus";
      break;
    case dashbordType.Categories:
      typeName = "Categories";
      break;
    case dashbordType.items:
      typeName = "items";
      break;
    case dashbordType.options:
      typeName = "options";
      break;
    case dashbordType.sort:
      typeName = "sort";
      break;
    default:
      return null;
  }
  return typeName;
};
