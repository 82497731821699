import React from "react";
import BranchForm from "./form/branchForm";

import { indexStore } from "../../../store/indexStore";
import { MainDialog } from "src/Components/Dialgos/MainDialog";
import { useTranslation } from "react-i18next";

function EditBranchModel(props) {
  const { t } = useTranslation();
  const { branch, UpdateBranchesFunction, featchBranchFunction } = indexStore();

  const handelEditBranch = (values) => {
    const common = typeof values.image_common;
    const newT = typeof values.image_new;
    const offer = typeof values.image_offer;
    if (common === "string") {
      delete values.image_common;
    }
    if (newT === "string") {
      delete values.image_new;
    }
    if (offer === "string") {
      delete values.image_offer;
    }

    UpdateBranchesFunction({
      ...values,
      id: branch.id,
      restaurant_id: branch.restaurant_id,
    });
  };

  return (
    <MainDialog
      trigger={props.trigger}
      title={`${t("gloabl.edit")} ${t(`gloabl.branch`)}`}
    >
      <BranchForm
        datasource={branch}
        onSubmit={handelEditBranch}
        editMode={true}
      />
    </MainDialog>
  );
}

export default EditBranchModel;
