import { showResponseMessage } from "../Components/utils/handellApiResponse";
import catMenuServices from "../api-services/cat-menu-services";
export const createMenusSlice = (set) => ({
  menus: [],
  menu: {},
  menuLoading: false,
  menusLoading: false,

  fetchMenusAction: async (branchId) => {
    set((state) => ({
      ...state,
      menusLoading: true,
    }));
    try {
      const res = await catMenuServices.getMenus(branchId);
      set((state) => ({
        ...state,
        menus: res.data.data,
        menusLoading: false,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        menusLoading: false,
      }));
    }
  },
  fetchMenuAction: async (menuId) => {
    set((state) => ({
      ...state,
      menuLoading: true,
    }));
    try {
      const res = await catMenuServices.getMenu(menuId);
      set((state) => ({
        ...state,
        menu: res.data.data,
        menuLoading: false,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        menuLoading: false,
      }));
    }
  },

  insertMenuAction: async (payload) => {
    set((state) => ({
      ...state,
      menusLoading: true,
    }));
    try {
      const res = await showResponseMessage(
        catMenuServices.insertMenu(payload),
        "store"
      );
      set((state) => ({
        ...state,

        menus: [...state.menus, res.data.data],
        menusLoading: false,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        menusLoading: false,
      }));
    }
  },

  updateMenuAction: async (payload) => {
    set((state) => ({
      ...state,
      menusLoading: true,
    }));
    try {
      const res = await showResponseMessage(
        catMenuServices.updateMenu(payload),
        "update"
      );

      set((state) => ({
        ...state,

        menus: state.menus.map((e) => {
          if (e.id === payload.id) e = res.data.data;
          return e;
        }),
        menusLoading: false,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        menusLoading: false,
      }));
    }
  },
  deleteMenuAction: async (id) => {
    set((state) => ({
      ...state,
      menusLoading: true,
    }));
    try {
      const res = await showResponseMessage(
        catMenuServices.deleteMenu(id),
        "delete"
      );

      set((state) => ({
        ...state,

        menus: state.menus.filter((e) => e.id !== id),
        menusLoading: false,
      }));
    } catch (error) {
      set((state) => ({
        ...state,
        menusLoading: false,
      }));
    }
  },
  setMenu: (menu) => set((state) => ({ ...state, menu: menu })),
});
