import React from "react";
import { useTranslation } from "react-i18next";
import { indexStore } from "src/store/indexStore";
import { MainDialog } from "../Dialgos/MainDialog";
import SocialMediaForm from "./form/SocialMediaForm";

function EditSocialMediaModel(props) {
  const { t } = useTranslation();
  const { socialMediaElemnt, updateSocialMediaFunction } = indexStore();
  const onSubmit = (values) => {
    const submitValues = {
      id: socialMediaElemnt.id,
      restaurant_id: socialMediaElemnt.restaurant_id,
      ...values,
    };
    updateSocialMediaFunction(submitValues);
  };
  return (
    <MainDialog
      trigger={props.trigger}
      title={`${t("gloabl.edit")} ${t("gloabl.socialMedia")}`}
    >
      <SocialMediaForm
        editMode={false}
        onSubmit={onSubmit}
        datasource={socialMediaElemnt}
      />
    </MainDialog>
  );
}

export default EditSocialMediaModel;
