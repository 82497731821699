import { DataTable } from "src/Components/DataTable/DataTable";
import React from "react";
import { citiesColumns, countriesColumns } from "./CountiesColmns";
import { indexStore } from "src/store/indexStore";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "src/Components/ui/card";
import { t } from "i18next";

function CitiesAndCountries(props) {
  const { cities, countries, infoTotal } = indexStore();
  const colCities = citiesColumns(infoTotal);
  const colCountries = countriesColumns(infoTotal);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
      <Card className="h-full flex flex-col">
        <CardHeader>
          <CardTitle>{`${t("gloabl.top")} ${t("gloabl.cities")}`}</CardTitle>
          <CardDescription></CardDescription>
        </CardHeader>
        <CardContent className="flex-grow overflow-y-auto">
          {/* <div className=" overflow-y-auto"> */}
          <DataTable columns={colCities} data={cities} />
          {/* </div> */}
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>{`${t("gloabl.top")} ${t("gloabl.countries")}`}</CardTitle>
          <CardDescription></CardDescription>
        </CardHeader>
        <CardContent>
          <DataTable columns={colCountries} data={countries} />
        </CardContent>
      </Card>
    </div>
  );
}

export default CitiesAndCountries;
