import React from "react";
import { MainDialog } from "../Dialgos/MainDialog";
import SocialMediaForm from "./form/SocialMediaForm";
import { useTranslation } from "react-i18next";
import { indexStore } from "src/store/indexStore";

function AddSocailMediaModel(props) {
  const { t } = useTranslation();
  const { storeSocialMediaFunction } = indexStore();
  const onSubmit = (values) => {
    storeSocialMediaFunction(values);
  };
  return (
    <MainDialog
      trigger={props.trigger}
      title={`${t("gloabl.add")} ${t("gloabl.socialMedia")}`}
    >
      <SocialMediaForm editMode={false} onSubmit={onSubmit} />
    </MainDialog>
  );
}

export default AddSocailMediaModel;
