"use client";

import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";

import { cn } from "src/lib/utils";
import { Button } from "src/Components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "src/Components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/Components/ui/popover";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { indexStore } from "src/store/indexStore";

export function Combobox(props) {
  const { t } = useTranslation();
  const { nameLang } = indexStore();
  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-72 justify-between"
        >
          {props.value
            ? props.data &&
              props.data.find((row) => row.id == props.value)?.[props.lable]
            : props.placeholder}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-72 p-0">
        <Command>
          <CommandInput placeholder={t("gloabl.search") + "..."} />
          <CommandList>
            <CommandEmpty>{t("errorMessage.noData")}</CommandEmpty>
            <CommandGroup>
              {props.data &&
                props.data.map((row) => (
                  <CommandItem
                    className="cursor-pointer"
                    key={row.id}
                    value={row.id}
                    onSelect={(currentValue) => {
                      props.onChange(row.id);
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        props.value == row.id ? "opacity-100" : "opacity-0"
                      )}
                    />
                    {row[props.lable]}
                  </CommandItem>
                ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
