import React, { useEffect } from "react";
import ItemForm from "./form/itemForm";
import { dashbordType } from "../../../enums/dashbordType";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { indexStore } from "../../../store/indexStore";
import { MainDialog } from "src/Components/Dialgos/MainDialog";
import { useTranslation } from "react-i18next";

const EditItemsModel = (props) => {
  const { t } = useTranslation();

  const { categories, item, updateItemsFunction, fetchItemFunction } =
    indexStore();

  // const navigate = useNavigate();

  // const { id, branchId } = useParams();

  // useEffect(() => {
  //   fetchItemFunction(id);
  // }, []);

  const handleSubmitAddCategory = (values) => {
    const t = typeof values.image;
    if (t === "string") {
      delete values.image;
    }

    const p = {
      ...values,
      id: item.id,
    };
    updateItemsFunction(p);
  };

  return (
    <MainDialog
      // open={props.open}
      trigger={props.trigger}
      title={`${t("gloabl.edit")} ${t(`gloabl.item`)}`}
    >
      <ItemForm
        type={dashbordType.items}
        onSubmit={handleSubmitAddCategory}
        editMode={true}
        datasource={item}
        menusOptions={categories}
      />
    </MainDialog>
  );
};

export default EditItemsModel;
