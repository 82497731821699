import React from "react";
import ResturantForm from "./form/resturantForm";
import { indexStore } from "../../../store/indexStore";
import { MainDialog } from "src/Components/Dialgos/MainDialog";
import { useTranslation } from "react-i18next";

function AddResturantModel(props) {
  const { t } = useTranslation();
  const { insertResturantsFunction } = indexStore();
  const handleSubmitAddRestaurant = (values) => {
    insertResturantsFunction({ ...values });
  };

  // const changeOpen = (e) => {
  //   console.log(e);
  //   props.onOpenChange(e);
  // };
  return (
    <MainDialog
      trigger={props.trigger}
      title={`${t("gloabl.add")} ${t(`gloabl.restaurant`)}`}
    >
      <ResturantForm
        onSubmit={handleSubmitAddRestaurant}
        editMode={false}
        // changeOpen={(e) => changeOpen(e)}
      />
    </MainDialog>
  );
}

export default AddResturantModel;
