import React, { useEffect } from "react";
import { axiosInstance } from "./axios-instance";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button } from "./Components/ui/button";
import { Form, FormField } from "./Components/ui/form";
import FormFieldItem from "./common/form/FormFieldItem";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "./Components/ui/input";
import { InputPassword } from "./Components/utils/InputPassword";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = (values) => {
    // console.log("Success:", values);
    axiosInstance
      .post("/loginTenant", values)
      .then((res) => {
        if (res.data.data.userTenant.name) {
          localStorage.setItem("restName", res.data.data.userTenant.name);
        } else if (res.data.data.restaurant) {
          localStorage.setItem("restName", res.data.data.restaurant.domin);
        }
        localStorage.setItem("token", res.data.data.token);
        localStorage.setItem(
          "premssions",
          res.data.data.userTenant.sumPermessions
        );
        navigate("/");
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 422 &&
          (error.response.data.errorCode === 2 ||
            error.response.data.errorCode === 1)
        ) {
          toast.error(t("errorMessage.worngLogin"));
        }
      });
  };
  const formSchema = z.object({
    phone: z
      .string({
        required_error: `${t("gloabl.Username")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.Username")} ${t(
          "errorMessage.fieldCharsLength"
        )}.`,
      }),
    userPassTenant: z
      .string({
        required_error: `${t("gloabl.Password")} ${t(
          "errorMessage.fieldRequired"
        )}.`,
      })
      .min(2, {
        message: `${t("gloabl.Password")} ${t(
          "errorMessage.fieldCharsLength"
        )}.`,
      }),
  });

  // 1. Define your form.
  const form = useForm({
    resolver: zodResolver(formSchema),

    defaultValues: {},
  });

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      // Token doesn't exist, navigate to the login page
      navigate("/");
    }
  }, []);

  const onErorr = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <img
        alt=""
        src={require("./assets/TWH1-black.png")}
        className="w-32 h-16 p-2"
      />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit, onErorr)}
          className="space-y-8"
        >
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormFieldItem required label={t("gloabl.Username")} form={form}>
                <Input placeholder="" {...field} />
              </FormFieldItem>
            )}
          />
          <FormField
            control={form.control}
            name="userPassTenant"
            render={({ field }) => (
              <FormFieldItem required label={t("gloabl.Password")} form={form}>
                <InputPassword
                  name={"userPassTenant"}
                  control={form.control}
                  {...field}
                />
              </FormFieldItem>
            )}
          />
          <Button type="submit">{t("gloabl.Login")}</Button>
        </form>
      </Form>
    </div>
  );
};
export default Login;
