import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const downloadPDF = async () => {
  const qrCodeCanvas = document.getElementById("qrcode-canvas"); // يختار أول عنصر `canvas` 
console.log(qrCodeCanvas);

  if (qrCodeCanvas) {
    const canvas = await html2canvas(qrCodeCanvas);

    const imgData = canvas.toDataURL("image/png"); // تحويل `canvas` إلى صورة
    const pdf = new jsPDF();

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const size = Math.min(pdfWidth - 20, pdfHeight - 20);

    pdf.addImage(
      imgData,
      "PNG",
      10, // الإحداثيات الأفقية
      10, // الإحداثيات العمودية
      size, // العرض
      size // الارتفاع
    );

    pdf.save("qrcode.pdf");
  } else {
    console.error("QR code canvas not found");
  }
};
