import React from "react";

import { useTranslation } from "react-i18next";
import Visibility from "../utils/Visibility";

import { MdOutlineDelete } from "react-icons/md";
import { indexStore } from "../../store/indexStore";
import { AlertDialogs } from "src/Components/Dialgos/Alter-Daylogs";
import EditSubOptionModel from "src/Components/pages/options/subOption/EditSubOptionModel";
import { CiEdit } from "react-icons/ci";

function SubOptionRow(props) {
  const { nameLang, deleteSubOptionFunction, setSubOption } = indexStore();
  const { t } = useTranslation();
  const onDelete = (element) => {
    deleteSubOptionFunction(element);
  };
  const editRow = (element) => {
    setSubOption(element);
  };
  return (
    <div className="grid grid-cols-3 justify-between items-center">
      <div className="flex items-center">
        <span>{props[nameLang]}</span>
      </div>
      <div dir="ltr" className="flex items-center text-center justify-center">
        <Visibility visible={props.price != 0}>
          <Visibility visible={props.price > 0}>
            <span>+</span>
          </Visibility>
          <span>{props.price} </span>
        </Visibility>
      </div>
      <div className="flex gap-2 justify-end items-center">
        <EditSubOptionModel
          trigger={
            <CiEdit
              className="text-xl cursor-pointer"
              onClick={() => editRow(props)}
            />
          }
        />

        <AlertDialogs
          trigger={
            <MdOutlineDelete className="text-xl cursor-pointer text-red-600 " />
          }
          variant="icon"
          onClickOk={() => onDelete(props)}
          description={<spn>{t("gloabl.deleteMessage")}</spn>}
          title={`${t("gloabl.delete")} ${t(`gloabl.sub_option`)}`}
        />
      </div>
    </div>
  );
}

export default SubOptionRow;
